
import React, { useState } from 'react';
import axios from 'axios';

export default function FindPasswordForm() {
  const [userEmail, setUserEmail] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);  // 인증번호 전송 성공 상태
  const [serverAuthCode, setServerAuthCode] = useState('');  // 서버에서 보낸 인증번호를 저장할 상태
  // 새로운 비밀번호를 저장할 상태
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);  // 비밀번호 변경 성공 상태
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // 비밀번호 변경 함수
  const changePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('비밀번호가 일치하지 않습니다. 다시 확인해주세요.');
      return;
    }
    
    const token = localStorage.getItem('accessToken');
    try {
      const response = await axios.post('/api/updateUserPassword', {
        userEmail,
        newPassword
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        alert('비밀번호가 성공적으로 변경되었습니다.');
        // 로그인 페이지로 이동합니다.
        window.location.href = '/login';

        // setIsPasswordChanged(true);
      } else {
        alert('비밀번호 변경에 실패하였습니다. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('비밀번호 변경 요청 중 에러가 발생했습니다:', error);
    }
  };

  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };
  const handleAuthCodeChange = (event) => {  // 인증번호 입력 필드의 값을 처리하는 함수
    setAuthCode(event.target.value);
  };

  // 인증번호를 검증하는 함수
  const verifyAuthCode = () => {
    // const serverAuthCode = '123456';  // 서버에서 받은 인증번호
    // console.log("인증번호 검증", authCode, serverAuthCode);
    if (String(authCode) === String(serverAuthCode)) {
      alert('인증번호가 일치합니다.');
      setIsPasswordChanged(true);
    } else {
      alert('인증번호가 일치하지 않습니다. 다시 확인해주세요.');
    }
  };

  // 인증번호 전송을 처리하는 함수 (추후 구현 필요)
  const handleSubmit = (event) => {
    event.preventDefault();
    // const phonenumber = '01099501140';
    // 인증번호 전송 로직 구현
    // console.log("인증번호 전송", userEmail);
    fetch('/emailAuthFindPassword', {
      method: 'POST',
      body: JSON.stringify({ userEmail }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 400) {
          throw new Error('가입되지 않는 이메일입니다. 회원가입 후 이용해주세요.');
        } else if (response.status === 401) {
          throw new Error('너무 많은 인증번호 요청입니다. 30초 후에 다시 시도해주세요.');
        } else if (response.status === 402) {
          throw new Error('이미 가입 된 휴대전화번호입니다. 다른 휴대전화번호를 사용해주세요.');
        } else {
          return response.json();
        }
      })
      .then(data => {
        // 검증 결과에 따른 처리 로직을 구현하세요.
        if (data.success) {
          setIsCodeSent(true);
          setServerAuthCode(data.authInfo.code);  // 서버에서 보낸 인증번호를 저장
          // console.log("인증번호 전송 성공", data.authInfo.code);
          setServerAuthCode(data.authInfo.code);
          alert('인증번호를 보냈습니다. 이메일을 확인해주세요.');
        } else {
          alert('인증번호를 보내는 데 실패했습니다. 다시 시도해주세요.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert(error.message);
      });
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={process.env.PUBLIC_URL + '/assets/coudaemonster.png'}
            alt="쿠대몬스터"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            이메일로 인증번호 받기
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                이메일 입력
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={userEmail}
                  onChange={handleEmailChange}
                  required
                  disabled={isCodeSent}  // 추가
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              {!isCodeSent && (
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  인증번호 전송
                </button>
              )}
            </div>

            {isCodeSent && !isPasswordChanged && (
              <>
                <div>
                  <label htmlFor="authCode" className="block text-sm font-medium leading-6 text-gray-900">
                    인증번호 입력
                  </label>
                  <div className="mt-2">
                    <input
                      id="authCode"
                      name="authCode"
                      type="text"
                      value={authCode}
                      onChange={handleAuthCodeChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={verifyAuthCode}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    인증번호 확인
                  </button>
                </div>
              </>
            )}
            {isPasswordChanged && (
              <div>
                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-900">
                    새로운 비밀번호 입력
                  </label>
                  <div className="mt-2">
                    <input
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      pattern="^(?=.*[a-z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$"
                      title="8자 이상의 영문 대소문자, 숫자, 특수문자를 사용하세요"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                    비밀번호 확인
                  </label>
                  <div className="mt-2">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <br />
                  <button
                    type="button"  // type을 'button'으로 변경
                    onClick={changePassword}
                    className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    비밀번호 변경
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  )
}
