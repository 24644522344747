import { Box, Button, TextField, Typography, Link } from "@mui/material";
// import { ColorModeContext } from "../../theme_dark_light";
import { Formik } from "formik";
// import { useContext } from "react";
import * as yup from "yup";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useState, useEffect } from "react";
// import Avatar from '@mui/material/Avatar';
// // import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// // import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("유효한 이메일 주소를 입력해주세요")
    .required("이메일을 입력해주세요"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "8자 이상의 영문 대소문자, 숫자, 특수문자를 사용하세요"
    )
    .required("비밀번호를 입력해주세요"),
});

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://coudae.monster/">
//         Coudae Monster
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }
const lightTheme = createTheme({
  palette: {
    mode: 'light', // 라이트 모드로 명시적 설정
    // ... 다른 색상 설정들
  },
  // ... 다른 테마 설정들
});
const Login = () => {
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState(null);
  const [loginMessage, setLoginMessage] = useState('');

  useEffect(() => {
    // Check if the tokens exist
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (accessToken && refreshToken) {
      // If the tokens exist, redirect to the /team page
      navigate('/');
    }
  }, [navigate]); // Add navigate to the dependency array

  // This function will be called when the form is submitted
  const handleFormSubmit = async (values) => {
    // console.log("Form values:", values);
    try {
      const response = await fetch('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("데이트",data);

      if (data.success) {
        // console.log("Login successful:", data);
        // Save the tokens in the browser
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('level', data.level);
        setLoginStatus(true);
        setLoginMessage('로그인에 성공했습니다.');
        navigate('/');
      } else {
        // Handle login success, e.g., by setting user state or redirecting
        setLoginStatus(false);
        setLoginMessage(data.msg);
      }
    } catch (error) {
      console.error("Login failed:", error);
      setLoginStatus(false);
      setLoginMessage('로그인에 실패했습니다.');
      // Handle login error, e.g., by displaying a notification
    }
  };

  // 추정된 색상 코드 (실제 색상과 다를 수 있음)
  // const primaryColor = '#FFFFFF';  // 진한 파란색
  // const secondaryColor = '#FFFFFF'; // 노란색
  // const accentColor = '#FFFFFF';    // 붉은색
  const primaryColor = '#000000';  // 진한 파란색
  const secondaryColor = '#1C4097'; // 노란색
  const accentColor = '#A6B0D6';    // 붉은색

  return (
    <ThemeProvider theme={lightTheme}>
      <Container component="main" maxWidth="xs">
        <Box
          m="30px"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ color: primaryColor }} // 텍스트 색상 지정
        >
          {/* <Typography variant="h2" component="h1" gutterBottom>
            쿠대몬스터 로고
          </Typography> */}
          <Box onClick={() => navigate('/')} display="flex" justifyContent="center" alignItems="center">
            <img

              alt="logo"
              width="200%"
              height="200%"
              src={`../../assets/coudaemonster.png`}
              style={{ cursor: "pointer", borderRadius: "10%" }}
            />
          </Box>
          <Typography variant="h5" component="h1" gutterBottom>
            로그인
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: 360 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="이메일" // '아이디'에서 '이메일'로 변경
                  name="email" // 'username'에서 'email'로 변경
                  onChange={handleChange}

                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  // Input 스타일 지정
                  inputProps={{
                    style: {
                      color: primaryColor,
                      borderColor: primaryColor,
                    },
                  }}
                  // Input Label 스타일 지정
                  InputLabelProps={{
                    style: { color: primaryColor },
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="비밀번호"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  // Input 스타일 지정
                  inputProps={{
                    style: {
                      color: primaryColor,
                      borderColor: primaryColor,
                    },
                  }}
                  // Input Label 스타일 지정
                  InputLabelProps={{
                    style: { color: primaryColor },
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: secondaryColor, // 버튼 배경색 지정
                    '&:hover': {
                      backgroundColor: accentColor, // 버튼 호버 색상 지정
                    },
                    width: '100%', // 너비를 100%로 설정
                  }}
                >
                  로그인
                </Button>
                <Box mt={2}></Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => navigate('/signup')}
                  sx={{
                    backgroundColor: secondaryColor, // 버튼 배경색 지정
                    '&:hover': {
                      backgroundColor: accentColor, // 버튼 호버 색상 지정
                    },
                    width: '100%', // 너비를 100%로 설정
                  }}
                >
                  회원가입
                </Button>
                <Box mt={2}></Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => navigate('/findpassword')}
                  sx={{
                    backgroundColor: secondaryColor, // 버튼 배경색 지정
                    '&:hover': {
                      backgroundColor: accentColor, // 버튼 호버 색상 지정
                    },
                    width: '100%', // 너비를 100%로 설정
                  }}
                >
                  비밀번호 찾기
                </Button>
                <Box mt={2}>
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="space-between" // 버튼 사이에 공간을 균등하게 배분
                  >

                  </Box>
                  <Box mt={2}></Box>
                  <Link href="/signup" variant="body2" sx={{ color: secondaryColor }}>
                    {"아이디가 없으신가요 ? 회원가입"}
                  </Link>
                </Box>
              </form>
            )}
          </Formik>
          {/* Add this Dialog component */}
          <Dialog
            open={loginStatus !== null}
            onClose={() => setLoginStatus(null)}
          >
            <DialogTitle>{loginStatus ? '로그인 성공' : '로그인 실패'}</DialogTitle>
            <DialogContent>
              <DialogContentText>{loginMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setLoginStatus(null)}>닫기</Button>
            </DialogActions>
          </Dialog>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} align="center" /> */}
      </Container>
    </ThemeProvider>
  );
};

const initialValues = {
  email: "", // 'email' 키를 'username'으로 변경
  password: "",
};

// const loginSchema = yup.object().shape({
//   userEmail: yup.string().required("아이디는 필수 입력해야하는 칸입니다"), // 이메일 검증 대신 간단한 문자열 검증
//   password: yup.string().required("비밀번호는 필수 입력해야하는 칸입니다"),
// });



export default Login;
