import { useState } from "react";
import { Routes, Route, useLocation,Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
// import TopbarDashboard from "./scenes/global/TopbarDashboard2";
import TopbarDashboard from "./scenes/global/TopbarDashboard";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard/index";
import Team from "./scenes/team";
// import Invoices from "./scenes/invoices";
// import Contacts from "./scenes/contacts";
// import Bar from "./scenes/bar";
// import Form from "./scenes/form";
// import Line from "./scenes/line";
// import Pie from "./scenes/pie";
// import FAQ from "./scenes/faq";
// import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
// import { ColorModeContext, useMode } from "./theme_dark_light";
// import Calendar from "./scenes/calendar/calendar";
import MyPage from "./scenes/mypage";
import NotFoundPage from "./scenes/global/404";
import Login from "./scenes/login/index";
import SignUp from "./scenes/signup/index";
import Pricing from "./scenes/pricing/index";
import FindIdForm from "./scenes/findid";
import FindPasswordForm from "./scenes/findpassword";
import FooTer from "./scenes/global/footer";

import PayMent from "./scenes/payment/index";
import MarketingAgreeForm from "./scenes/marketingAgreeForm/index";
import PrivacyForm from "./scenes/privacy/index";

function ProtectedRoute({ children }) {
  const location = useLocation();
  const token = localStorage.getItem('accessToken');

  // 토큰이 있는지 확인
  if (token) {
    return children;
  } else {
    // 토큰이 없을 경우 로그인 페이지로 리다이렉트
    return <Navigate to="/login" state={{ from: location }} />;
  }
}

function ProtectedLevelRoute({ children }) {
  const location = useLocation();
  const token = localStorage.getItem('accessToken');
  const userLevel = parseInt(localStorage.getItem('level'), 10); // 'userLevel'은 사용자 레벨을 저장하는 키
  // console.log(userLevel);
  // 로그인 상태 확인 및 레벨이 3 이상인지 확인
  if (token && userLevel === 98) {
    return children;
  } else {
    // 로그인 상태가 아니거나 레벨이 충분하지 않을 경우 로그인 페이지로 리다이렉트
    return <Navigate to="/login" state={{ from: location }} />;
  }
}

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation(); // 현재 경로를 얻기 위해 useLocation 훅 사용

  // 로그인 및 회원가입 페이지에 대해 사이드바와 탑바를 숨기기 위한 조건
  const hideSidebarAndTopbar = location.pathname === '/findid' ||location.pathname === '/findpassword' ||location.pathname === '/pricing' || location.pathname === '/login' || location.pathname === '/signup'|| location.pathname === '/marketingagree' ||location.pathname === '/privacy' || location.pathname === '/' ;
  const isMyPage = location.pathname === '/mypage' || location.pathname === '/payment';
  // 마이페이지 렌더링
  if (isMyPage) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <TopbarDashboard setIsSidebar={setIsSidebar} />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app full">
            <main className="content" >
                <Routes>
                  {/* 로그인 및 회원가입 라우트 */}
                  
                  <Route path="/mypage" element={<ProtectedRoute> <MyPage /> </ProtectedRoute>} />
                  <Route path="/payment" element={<ProtectedRoute> <PayMent /> </ProtectedRoute>} />
                  {/* <Route path="*" element={<NotFoundPage />} /> */}
                  {/* ... */}
                </Routes>
              </main>
          </div>
        </ThemeProvider>
        <FooTer />
      </ColorModeContext.Provider>
    );
  }

  if (hideSidebarAndTopbar) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <TopbarDashboard setIsSidebar={setIsSidebar} />
          <div className="app full">
            <main className="content" >
                <Routes>
                  {/* 로그인 및 회원가입 라우트 */}
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/findid" element={<FindIdForm />} />
                  <Route path="/findpassword" element={<FindPasswordForm />} />
                  <Route path="/marketingagree" element={<MarketingAgreeForm />} />
                  <Route path="/privacy" element={<PrivacyForm />} />
                  <Route path="*" element={<NotFoundPage />} />
                  {/* ... */}
                </Routes>
              </main>
          </div>
        </ThemeProvider>
        {(location.pathname !== '/privacy' && location.pathname !== '/marketingagree') && <div style={{ marginTop: '40px' }}><FooTer /></div>}

      </ColorModeContext.Provider>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              {/* <Route path="/" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} /> */}
              <Route path="/team" element={<ProtectedLevelRoute> <Team /> </ProtectedLevelRoute>} />
              
              {/* <Route path="/contacts" element={<ProtectedRoute> <Contacts /> </ProtectedRoute>} />
              <Route path="/faq" element={<ProtectedRoute> <FAQ /> </ProtectedRoute>} />
              <Route path="/invoices" element={<ProtectedRoute> <Invoices /> </ProtectedRoute>} />
              <Route path="/form" element={<ProtectedRoute> <Form /> </ProtectedRoute>} />
              <Route path="/bar" element={<ProtectedRoute> <Bar /> </ProtectedRoute>} />
              <Route path="/pie" element={<ProtectedRoute> <Pie /> </ProtectedRoute>} />
              <Route path="/line" element={<ProtectedRoute> <Line /> </ProtectedRoute>} />
              <Route path="/calendar" element={<ProtectedRoute> <Calendar /> </ProtectedRoute>} />
              <Route path="/geography" element={<ProtectedRoute> <Geography /> </ProtectedRoute>} />
               */}
              {/* <Route path="/mypage" element={<ProtectedRoute> <MyPage /> </ProtectedRoute>} /> */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
      <FooTer />
    </ColorModeContext.Provider>
  );


}

export default App;
