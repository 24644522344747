import React from 'react';

export default function MarketingAgreeForm() {
  return (
    <div>
<p>제1조(목적)</p>
<br></br>
<p>본 약관은 주식회사 텐마일즈(이하 '회사')가 온라인 및 오프라인에서 제공하는 서비스(이하 '서비스')를 제공하는 텐마일즈(이하 '이용자')의 권리와 의무 및 기타 제반 사항을 규정함을 목적으로 합니다.</p>
<br></br>

<p>제2조(회원약관의 고객 및 변경)</p>
<br></br>
<pre>1) 이 약관은 서비스 웹사이트에 공지된 전자우편의 방법으로 이용자에게 공지되어 그 효력이 발생합니다.</pre>
<pre>2) 법령 또는 정부정책의 변경 등 관련 사항을 반영해야 할 경우, 회사는 사전고지 없이 이 약관을 변경할 수 있습니다. 이 경우 제2조 1항과 동일한 방법으로 이용자에게 공지함으로써 그 효력이 발생합니다.</pre>
<pre>3) 약관을 변경하는 경우에는 변경일자 및 변경사유를 기재별로 정리하여 제2조 1항과 동일한 방법으로 해당 변경일자 7일 이전부터 공지합니다. 다만, 이용자에게만 약관 내용을 변경하는 경우에는 30일 동안 사전 유예기간을 알려드립니다. 이 경우 회사는 전후 내용을 비교하여 이용자가 이해할 수 있도록 표시합니다.</pre>
<pre>4) 이 약관에 동의하는 것은 서비스 웹사이트에 방문하여 약관의 변경 사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알리지 않고 발생하는 이용자의 피해는 회사에서 책임을 지지 않습니다.</pre>
<pre>5) 이용자는 변경된 약관에 동의하지 않는 경우 회원 탈퇴(해제)를 요청할 수 있으며, 변경된 약관이 적용되는 시기 이후에도 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한다고 간주합니다.</pre>
<pre>6) 이 조항에서 정하지 아니한 사항과 이 조항의 해석에 관한 원칙은 전자상거래 등 소비자 보호에 관한 법령, 공정거래위원회가 제정한 전자상거래 등 소비자 보호지침 및 관계법률, 전기 통신기본법, 전기통신사업법, 정보통신망이용촉진 및 정보보호에 관한 법률, 개인정보보호법 상 관례에 준합니다.</pre>

<p>제3조(용어의 정의)</p>
<br></br>
<pre>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</pre>
<pre>1) '이용자'라 함은 서비스를 제공받기 위해 회사와 계약을 맺고 사용자 ID(아이디)를 부여받은 사람을 말합니다.</pre>
<pre>2) '사용자(회원 또는 가입자 또는 이용자) ID(아이디)'라 함은 이용자의 자격을 식별하고 이용자가 서비스를 이용하기 위하여 회사가 부여하는 문자와 숫자의 조합을 말합니다.</pre>
<pre>3) '비밀번호(암호)'라 함은 이용자가 사용하는 사용자 ID와 일치하는 이용자를 인증하고 이용자의 권익보호를 위해 이용자가 선택한 문자와 숫자의 조합을 말합니다.</pre>
<pre>4) '해지'라 함은 회사 또는 이용자가 이용계약을 해약하는 것을 말합니다.</pre>
<pre>5) '사이트'라 함은 회사가 각종 서비스 웹사이트를 운영하고 있음을 말합니다.</pre>

<p>제4조(회원 가입)</p>
<br></br>
<pre>1) 이 약관에 대한 동의는 이 약관 및 개인정보 처리방침에 동의한다는 의사표시와 함께 회사가 요청하는 정보를 제공함으로써 회원가입 신청을 완료함으로써 이루어집니다.</pre>
<pre>2) 이용자로 가입하여 서비스를 이용하고자 하는 경우 이 조항에서 회사가 요청하는 제반 정보(개인정보처리방침의 '수집하는 개인정보의 항목' 포함)를 제공합니다.</pre>
<pre>3) 이용자는 본인의 정보를 제공하지 않은 경우 서비스 이용과 관련하여 권리를 주장할 수 없으며, 관계 법령 및 회사가 정한 규정, 규정 등을 준수하여야 합니다. 또한 제 3자에게 손해를 야기한 경우 해당 손해배상 책임을 인정하고, 회사는 허위의 정보를 기재한 이용자의 서비스 이용 자격을 제한할 수 있습니다.</pre>

<p>제5조(개인정보 보호 및 사용)</p>
<br></br>
<pre>1) 회사는 이용자의 개인정보를 보호하기 위해 노력합니다. 개인 정보의 보호 및 사용에 관한 구체적인 내용은 회사의 개인 정보 처리 정책에 정한 바에 따릅니다. 이용자는 통합 ID 및 비밀번호 등을 타인에게 유출시키지 않도록 주의해야 하며 단 회사는 이용자의 귀책사유로 인한 정보 유출에 대해 책임을 지지 않습니다.</pre>
<pre>2) 회사는 이용자가 제공하는 개인정보를 본 서비스 제공의 목적을 위해서만 사용합니다.</pre>
<pre>3) 회사는 이용자가 제공하는 개인정보를 이용자의 사전 동의 없이 제 3자에게 제공할 수 없습니다. 다만, 다음 각호에 해당하는 경우에는 예외로 합니다.</pre>
<pre>가. 검색엔진에게 검색서비스를 제공하는 경우</pre>
<pre>나. 정보통신망법, 전기통신기본법, 민사소송법, 형사소송법과 같은 관계법령에 의하여, 또는 다른 국가 기관의 요청이 있거나, 전자통신윤리위원회 등의 요청이 있고, 수사상의 목적이 있는 경우</pre>
<pre>다. 통계 작성, 학술 연구, 공익적 기록보존 등을 위해 필요한 경우로서 특정 이용자를 식별할 수 없는 형태로 제공되는 경우</pre>

<p>제6조 (이용자에 대한 부품 및 정보 제공)</p>
<br></br>
<pre>1) 회사는 이용자에게 정보를 제공하는 경우 이용자가 회사에 등록된 전자우편 주소, 휴대전화번호, 유선전화번호를 이용합니다.</pre>
<pre>2) 회사는 불특정다수 이용자에게 서비스 이용을 요청하는 경우 공지게시판을 통해 7일 이상 게시함으로써 개별 통지를 대신할 수 있습니다.</pre>
<pre>3) 회사는 이용자에게 서비스 이용에 필요하다고 인정되는 선택 정보에 대하여 전자우편이나 서신, 우편, SMS, 전화 등의 방법으로 이용자에게 제공할 수 있습니다.</pre>
<pre>4) 회사는 개선 및 이용자 대상의 서비스 소개 등의 목적으로 이용자의 동의 하에 관련 광고에 따라 추가 개인 정보를 수집할 수 있습니다.</pre>

<p>제7조(서비스의 이용 시간 및 중단)</p>
<br></br>
<pre>1) 서비스는 회사의 업무상 또는 기술상 특별한 지장이 없는 한 기간 무휴, 1일 24시간 동안 제공되어야 합니다. 단, 회사는 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스를 잠시 중단할 수 있으며, 예외적 사유로 서비스 중단을 하는 경우 사이트의 공지게시판 등을 통해 사전에 공지합니다.</pre>
<pre>2) 회사는 긴급한 시스템 구성, 증설 및 교체, 보안의 장애, 서비스 지원의 범위 초과, 국가비상사태, 정전 등 부득이한 사유가 있는 경우 사전 예고 없이 전체 서비스의 일부 또는 전부를 중단할 수 있습니다.</pre>
<pre>3) 회사는 개편 등 서비스 운영이 필요한 경우 전체 또는 일부 서비스를 제공하거나 중단하거나 임시로 백업할 수 없는 한 이용자에게 사전에 공지합니다.</pre>

<p>제8조 (회사의 의무)</p>
<br></br>
<pre>1) 회사는 약관이 정하는 바에 따라야 하며 서비스를 제공하기 위해 노력합니다.</pre>
<pre>2) 회사는 이용자의 개인정보 보호를 위해 보안 시스템을 구축하고 개인정보 처리방침을 준수합니다.</pre>
<pre>3) 회사는 공정하고 건전한 운영을 위해 최선을 다하고 계속 연구 개발을 통해 가치 있는 서비스를 제공하여 고객 만족을 증대시켜 인터넷 사업 발전에 기여합니다.</pre>
<pre>4) 회사는 이용자로부터 불편을 초래하는 청구가 정당하다고 인정되는 경우 적절한 절차를 처리합니다. 기계, 프로세스 처리가 중요한 경우는 이용자에게 그와 처리일을 정하는 것입니다.</pre>

<p>제9조 (이용자의 의무)</p>
<br></br>
<pre>1) 이용자는 회원 가입 신청 또는 이용자 정보 변경 시 모든 사항을 사실에 근거하여 본인의 실제 정보로 기재해야 하며, 허위 또는 부정확한 정보를 기재한 경우 서비스와 관련하여 그로 인한 손해 또는 실제 당사자의 권리는 주장할 수 없습니다.</pre>
<pre>2) 이용자는 본 약관에서 규정하는 사항 및 기타 회사가 정한 제반 규정, 공지사항 등 회사가 공지하는 사항을 준수하여야 하는 이유, 기타 회사의 업무에 방해가 되도록 하지 않으며, 회사의 명예를 훼손하지 않도록 주의해야 합니다.</pre>
<pre>3) 이용자는 주소, 전자우편 주소 등 이용자의 이용 정보가 변경된 경우 해당 절차를 담당하는 회사에 즉시 알려야 합니다.</pre>
<pre>4) 이용자는 ID, 비밀번호 등이 유출되지 않도록 관리할 권한이 있습니다.</pre>
<pre>5) 이용자는 ID, 비밀번호 등이 유출되거나 제 3자가 사용하고 있음을 인식하는 경우에는 즉시 회사에 신고하고 회사의 안내가 있는 경우에는 따라야 합니다.</pre>
<pre>6) 회사는 이용자가 본조를 위반함으로써 이용자 또는 제3자에게 불법 행위에 대해 책임을 지지 않습니다.</pre>

<p>제10조 (이용자 정지 및 자격 박탈)</p>
<br></br>
<pre>1) 이용자가 회사에 소속된 회원을 대신하여 이용 신청을 한 경우 회사는 지체 없이 해당 이용자의 회원 등록을 말소합니다. 다만, 이용자로 등록된 회원이 이용 신청을 한 경우 관련 등록 및 개인정보 처리 정책에 따라 휴면회원을 보유하는 경우 해당 회원의 모든 개인정보는 삭제됩니다. 이 경우 이용자가 게시물과 공용 게시판에 등록된 댓글 등은 삭제되지 않습니다.</pre>
<pre>2) 회사의 모든 서비스에서 사용 중인 서비스의 기간이 남아있는 회원이 회원탈퇴를 요청하는 경우 회원탈퇴로 서비스의 종료 또는 그와 관련된 서비스의 기간에 대한 모든 책임은 회원에게 해당됩니다.</pre>
<pre>3) 이용자가 서비스 이용에 포함되는 본 약관 제11조 내용을 위반하거나, 다음 각 호의 범위에 해당하는 경우 회사는 이용자 자격 정지 및 회원 탈퇴 조치를 할 수 있습니다.</pre>
<pre>가. 본인의 명의를 사용하여 신청하는 경우</pre>
<pre>나. 신청 시 필요 기재 사항을 허위로 기재한 경우</pre>
<pre>다. 관계법령의 용도를 위반하거나 위반할 가능성이 있는 경우</pre>
<pre>라. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청하거나 신청하는 경우</pre>
<pre>마. 관계 권리를 침해하거나 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자 거래질서를 침해하는 경우</pre>
<pre>4) 회사가 제3항에 따라 회원 자격 정지 및 회원 탈퇴 처리를 원하는 경우에는 이용자에게 자격 정지 기회를 부여합니다.</pre>

<p>제11조 (손해배상)</p>
<br></br>
<pre>1) 회사 또는 이용자가 본 약관에 위반하여 상대방에게 손해를 입힌 경우, 본 약관은 제외되며 별도로 처리합니다.</pre>
<pre>2) 이용자가 서비스를 이용함에 있어 발생한 손해나 본 약관에 따른 의무 위반으로 인한 손해에 대해 이용자는 회사에 배상할 책임이 있고, 회사가 제3자로부터 책임을 배상 청구받은 경우 이용자는 자신의 책임과 비용으로 회사의 면책 조항을 따릅니다. 그들은, 그로 인해 회사에게 모든 손해를 배상해야 합니다.</pre>

<p>제12조 (면책)</p>
<br></br>
<pre>1) 회사는 지진, 홍수, 전쟁, 기간통신 사업자의 서비스 종료 및 기타 목적을 달성하는 것을 거부하는 서비스를 제공할 수 없는 경우에는 서비스에 대한 책임을 지지 않습니다.</pre>
<pre>2) 회사는 부대의 부속, 교체, 정기점검, 보수 등 부득이한 사유로 인한 서비스 중단에 대해 책임을 지지 않습니다.</pre>
<pre>3) 회사는 무료로 제공하는 서비스의 이용과 관련하여 어떠한 책임도 지지 않습니다.</pre>
<pre>4) 회사는 이용자가 서비스에 게재한 정보, 사실의 진실성, 신뢰성 등 그 내용에 전혀 책임을 부담하지 않고, 이용자는 자기의 책임 아래 서비스를 이용하며, 서비스를 이용하여 게시하거나 데이터 축적과 관련하여 손해가 발생하거나 데이터의 취사선택, 기타 이용 서비스와 관련하여 어떠한 불공정 행위도 발생하지 않도록 모든 책임은 이용자에게 있습니다.</pre>
<pre>5) 회사가 제공하는 정보와 자료는 거래의 목적으로 이용할 수 없습니다. 따라서 본 서비스의 정보와 자료를 이용하여 거래한 경우 발생하는 모든 책임과 결과는 이용자에게 있습니다. 회사는 이용자의 서비스의 이용과 관련하여 재산상 손해에 대해 책임을 부담하지 않습니다.</pre>
<pre>6) 회사는 이용자가 서비스를 이용하여 수익을 창출할 수 없거나 손해를 입은 것에 대해 책임을 지지 않으며, 서비스를 이용하여 제공한 데이터로 인해 관계를 맺은 경우 책임을 지지 않습니다.</pre>
<pre>7) 회사는 이용자의 게시물을 등록하기 전에 미리 사전심사를 거쳐 게시물의 내용을 확인하거나 검토 결과를 확인한 후 결과에 대한 책임을 지지 않습니다.</pre>

<p>제13조(약관의 등록 및 등록 법원)</p>
<br></br>
<pre>1) 본 약관에 등록되지 않은 사항은 관계 법령에 따라 처리합니다.</pre>
<pre>2) 회사의 개별 유료/무료 서비스 이용 회원의 경우 해당 서비스와 관련하여 회사가 정한 부분적인 약관 및 규정이 우선 적용됩니다.</pre>
<pre>3) 이용자와 회사 사이에 분쟁이 발생하여 소송이 제기되는 경우를 제1심으로 서울중앙지방법원을 관할 법원으로 합니다.</pre>




<p>부칙(시행일)</p>
<br></br>
<p>본 약관은 2024년 1월 1일부터 적용됩니다.</p>
<br></br>

    </div>
  );
}
