const navigation = [
  // {
    // name: 'YouTube',
    // href: 'https://www.youtube.com/@octopusman',
    // icon: (props) => (
    //   <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //     <path
    //       fillRule="evenodd"
    //       d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
    //       clipRule="evenodd"
    //     />
    //   </svg>
    // ),
  // },
  {
    name: '네이버 카페',
    href: 'https://cafe.naver.com/coudae',
    icon: (props) => (
      <img src="../../assets/navercafe.webp" alt="네이버 카페 아이콘" {...props} />
    ),
  }
]

export default function FooTer() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2024 쿠대몬스터
            <br/>
          </p>
        <div className="mt-8 md:order-1 md:mt-0">

          <p className="text-center text-xs leading-5 text-gray-500">
            [수원광교본사] 경기도 수원시 영통구 창룡대로256번길 77 에이스광교타워3 815호 816호
          </p>
          <p className="text-center text-xs leading-5 text-gray-500">
            [수원인계동지점] 경기도 수원시 팔달구 인계동 1121-2 우성타워 9층
          </p>
          <p className="text-center text-xs leading-5 text-gray-500">
            주식회사 텐마일즈
          </p>
          <p className="text-center text-xs leading-5 text-gray-500">
            통신판매업신고증(제 2020-수원영통-0209 호)
          </p>
          <p className="text-center text-xs leading-5 text-gray-500">
            사업자번호(815-81-00828)
          </p>
        </div>
      </div>
    </footer>
  )
}