import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "@mui/material";
import { DataGrid , GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme_dark_light";
import { Dialog, DialogActions, DialogContent} from "@mui/material";

// import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AdminDetailPage from '../form/adminDetailPage';
import AdminPaymentPage from "../form/adminPaymentPage";



const Team = () => {

  const [userData, setUserData] = useState(null); // userData 상태 추가
  const [formModalOpen, setFormModalOpen] = useState(false);

  const [userPaymentData, setuserPaymentData] = useState(null); // userData 상태 추가
  const [paymentFormModalOpen, setPaymentFormModalOpen] = useState(false);

  // Form();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleFormModalOpen = async (userEmail) => {
    const data = { userEmail: userEmail }
    try {
      const response = await axios.post('/api/getUserByUserEmail', data);
      setUserData(response.data); // userData 상태 업데이트
      setFormModalOpen(true);
    } catch (error) {
      console.error("Error occurred while fetching user data: ", error);
      // 필요에 따라 추가적인 오류 처리 로직을 여기에 작성할 수 있습니다.
    }
  };
  const handleClickPayment = async (userEmail) => {
    const data = { userEmail: userEmail }
    // console.log("userEmadsdsdsdsafdasasfddasf@@@@@@il", userEmail)
    try {
      const response = await axios.post('/api/getUserPaymentByUserEmail', data);
      // console.log("response.data", response.data)
      const paymentData = response.data.map(item => {
        return {
          ...item,
          userEmail: userEmail, // 이 부분은 실제 응답 데이터 구조에 맞게 조정해야 할 수 있습니다.
          // amount: item.amount // 가정: response.data가 amount를 포함하고 있음
        };
      });
      // console.log("paymentData", paymentData)
  
      setuserPaymentData(paymentData); // userData 상태 업데이트
      setPaymentFormModalOpen(true);
    } catch (error) {
      console.error("Error occurred while fetching user data: ", error);
      // 필요에 따라 추가적인 오류 처리 로직을 여기에 작성할 수 있습니다.
    }
  };
  
  const handleFormModalClose = () => {
    setFormModalOpen(false);
    setUserData(null); // 모달을 닫을 때 userData 상태를 초기화
  };
  const handlePaymentFormModalClose = () => {
    setPaymentFormModalOpen(false);
    setuserPaymentData(null); // 모달을 닫을 때 userData 상태를 초기화
    setUserData(null); // 모달을 닫을 때 userData 상태를 초기화
  };


  // const handleDelete = async (id) => {

  //   const response = await fetch(`/api/deleteUser`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ id : id }),
  //   });
  
  //   try {
  //     if (response.status === 200) {
  //       // console.log('User deleted successfully');
        
  //       // teamData에서 삭제된 사용자를 제외한 새로운 배열을 만듭니다.
  //       const updatedTeamData = teamData.filter(user => user.id !== id);
        
  //       // 상태를 업데이트하여 UI를 새로 렌더링합니다.
  //       setTeamData(updatedTeamData);
        
  //     } else {
  //       // console.log('Failed to delete user');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the user', error);
  //   }
  // };
  
  // const handleConfirm = () => {
  //   handleDelete(selectedUsername);
  //   setOpen(false); // Close the confirmation dialog
  // };
  
  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const [open, setOpen] = useState(false);  // 추가
  // const [selectedUsername, setSelectedUsername] = useState('');  // 추가
  const [teamData, setTeamData] = useState([]);

  // 컴포넌트가 마운트 될 때 실행될 로직
  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log('Fetching data...');
        // axios를 사용하여 POST 요청 수행
        const response = await axios.post('/api/getAllUsers', {
          // POST 요청의 body 데이터. 필요하면 추가
          // key: 'value'
        });
        // console.log('Fetched data:', response.data);
        setTeamData(response.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
  
    // console.log('Calling fetchData...');
    fetchData();
  }, []);
  

  const columns = [
  
    { field: "id", headerName: "ID" },
    { field: "userEmail", headerName: "이메일", flex: 2 },
    {
      field: "username",
      headerName: "아이디",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "psword",
      headerName: "비밀번호",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "휴대폰 번호",
      flex: 1,
    },
    // {
    //   field: "token",
    //   headerName: "토큰",
    //   flex: 1,
    // },
    // { field: "vendorId", headerName: "벤더 ID", flex: 1 },
    { field: "expiredDate", headerName: "만료 날짜", flex: 1 },
    // { field: "bill_id", headerName: "결제청구 ID", flex: 1 },
    { field: "amount", headerName: "누적금액", flex: 1 },
    {
      field: "update",
      headerName: "회원수정",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Button 
            // 나중에 여기서 db 정보 수정하기 
              onClick={() => handleFormModalOpen(params.row.userEmail)}
              color="info"
              variant="contained"
            >
              수정
            </Button>

          </Box>
        );
      }
    },
    {
      field: "payment",
      headerName: "결제내역",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Button 
              onClick={() => handleClickPayment(params.row.userEmail)}
              color="warning"
              variant="contained"
            >
              결제내역
            </Button>
          </Box>
        );
      }
    },
    // {
    //   field: "delete",
    //   headerName: "삭제",
    //   sortable: false,
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Box>
    //         <Button 
    //           onClick={() => handleClickDelete(params.row.id)}
    //           color="warning"
    //           variant="contained"
    //         >
    //           삭제
    //         </Button>
    //       </Box>
    //     );
    //   }
    // },
    {
      field: "level",
      headerName: "권한레벨",
      flex: 1,
      renderCell: ({ row: { level } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              level === 1
                ? colors.redAccent[600]
                : level === 0
                ? colors.greenAccent[600]
                : level === 98
                ? colors.blueAccent[600]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {level === 0 && <LockOpenOutlinedIcon />}
            {level === 1 && <AdminPanelSettingsOutlinedIcon />}
            {level === 98 && <AdminPanelSettingsOutlinedIcon />}
            {level === "manager" && <SecurityOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {level === 1 ? "유료회원" : level === 0 ? "무료회원" : "관리자"}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="회원관리" subtitle="쿠대몬스터 회원관리" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {/* <DataGrid checkboxSelection rows={mockDataTeam} columns={columns} /> */}
        {/* <DataGrid 
          checkboxSelection 
          rows={teamData} 
          columns={columns}
          onSelectionModelChange={(newSelection) => {
            setSelectedIDs(newSelection.selectionModel);
          }} 
        /> */}


        <DataGrid checkboxSelection rows={teamData} columns={columns} components={{ Toolbar: GridToolbar }} />
      </Box>
        {/* <Dialog open={open} onClose={handleClose}>
        <DialogTitle>삭제 확인</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${selectedUsername}을(를) 정말로 삭제하시겠습니까?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button 
            onClick={() => {
              // 삭제 로직 실행
              handleConfirm();
              handleClose();
            }} 
            color="info"
          >
            확인
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* 새로 추가한 Form 모달 Dialog */}
      <Dialog open={formModalOpen} onClose={handleFormModalClose}>
        <DialogContent>
          <AdminDetailPage userData={userData} /> {/* userData를 prop으로 전달 */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormModalClose} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={paymentFormModalOpen}
        onClose={handlePaymentFormModalClose}
        sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 'none' } }} // 스타일 적용
      >
        <DialogContent>
          <AdminPaymentPage userPaymentData={userPaymentData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePaymentFormModalClose} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};



export default Team;
