import * as React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';
import PopUp from './popup';
import SystemRequirements from '../../components/requirecomputer';

const tiers = [
  {
    name: '무료',
    id: 'tier-hobby',
    href: '/',
    priceMonthly: '0원',
    description: '부담없이 시작해보세요.',
    features: [
      '설치 비용 : 무료',
      '상품 수집 : 무제한',
      '마켓 계정 : 무제한',
      '상품 등록 : 10,000개',
      '소싱처 : 알리익스프레스',
      '멀티탭(중복프로그램) : 최대 1개',
      '연동 마켓 : 쿠팡',
    ],
  },
  {
    isPremium: true,
    name: '유료',
    id: 'tier-team',
    href: '/payment',
    priceMonthly: '200,000원',
    description: '제한없이 사용하세요.',
    features: [
      '설치 비용 : 무료',
      '상품 수집 : 무제한',
      '마켓 계정 : 무제한',
      '상품 등록 : 무제한',
      '소싱처 : 알리익스프레스 , 아마존 , 도매매, 소싱처 개발중..',
      '멀티탭(중복프로그램) : 최대 2개(수집,업로드 포함)',
      '연동 마켓 : 스마트스토어 , 쿠팡 , 11번가 , 지마켓 , 옥션',
    ],
  },
]

export default function Pricing() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false); // 팝업 창 상태 관리

  // 로그인 여부 확인
  const checkLoggedIn = () => {
    const token = localStorage.getItem('accessToken');
    // console.log(token);
    return token;
  };

  // 버튼 클릭 핸들러
  const handleButtonClick = (tierName) => {
    if (!checkLoggedIn()) {
      console.log('로그인되지 않음, 팝업 창을 띄움');
      setOpenDialog(true);
      return;
    }

    // 유료 요금제 선택 시 결제 페이지로 이동
    if (tierName === '유료') {
      navigate('/payment');
    } else if (tierName === '무료') {
      navigate('/');
    }
  };

  return (
    <div className="isolate overflow-hidden bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">
            요금제
          </h2>
          
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            제한없는 <br className="hidden sm:inline lg:hidden" />
            대량 솔루션을 만나보세요.
          </p>
          <p className="text-center text-lg font-bold text-red-600 mb-4">
              <br></br>
            <span className="text-2xl">
              🎁 3월 4일 ~ 4월 1일까지 🎁
              <br></br>
               베타 오픈 기념 이벤트!
              <br>
              </br>30일 결제 시 + 30일 더! 총 60일!
              <br></br>
            </span>
          </p>
            <p className="text-white">
              *신규 프로그램 특성상 오류가 발생할 수 있습니다.
              <br></br> 
              *하지만 지금 결제하시면 30일 더 무료로 이용하실 수 있습니다.
            </p>
        </div>

        <div className="relative mt-6">
          <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
            {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit numquam eligendi quos odit doloribus
            molestiae voluptatum. */}
          </p>
          <svg
            viewBox="0 0 1208 1024"
            className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
          >
            <ellipse
              cx={604}
              cy={512}
              fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)"
              rx={604}
              ry={512}
            />
            <defs>
              <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#4F46E5" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            {/* 이벤트 문구 추가 */}
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    {/* 유료 요금제일 경우 이벤트 문구 표시 */}
                    {tier.isPremium && (
                      <p className="text-center text-lg font-bold text-red-600 mb-4">
                        {/* <span className="text-2xl">🎁</span> 3월 1일까지 30일 +
                        30일 더!
                        <br />
                        *선착순 100명 한정 */}
                      </p>
                    )}
                    <h4
                      id={tier.id}
                      color="#16357c"
                      className="text-base font-semibold leading-7 text-indigo-600"
                    >
                      {tier.name}
                    </h4>

                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        {tier.priceMonthly}
                      </span>
                      <span className="text-base font-semibold leading-7 text-gray-600">
                        /월
                      </span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p>
                    <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button
                    onClick={() => handleButtonClick(tier.name)}
                    style={{
                      backgroundColor: "#16357c",
                      padding: "10px",
                      fontSize: "20px",
                    }}
                    aria-describedby={tier.id}
                    className="mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    시작하기
                  </button>
                  {/* 팝업 창 (로그인 안내 등) */}
                  <PopUp open={openDialog} setOpen={setOpenDialog} />
                </div>
              ))}
            </div>
              <SystemRequirements/>
          </div>
        </div>
      </div>
    </div>
  );
}
