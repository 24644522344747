import React from 'react';

export default function PrivacyForm() {
  return (
    <div>
      <div>
        <p>텐마일즈(이하 '회사')는 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호 등에 관한 법률"을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다. 회사는 개인정보취급방침을 개정하는 경우 텐마일즈 대표 사이트(https://coudae.monster) 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</p>
        <pre>- 본 방침은 2021년 7월 21일 부터 시행됩니다.</pre>
        <br></br>

        <pre>1. 수집하는 개인정보의 항목</pre>
        <br></br>
        <p>회사는 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.</p>

        <pre>수집 항목</pre>
        <br></br>
        <p>- 아이디, 이메일, 비밀번호, 휴대전화번호, 서비스 이용기록, 접속 IP 정보</p>
        <p>- 입금자명, 사업자번호, 주소지, 대표자, 업태, 업종</p>

        <pre>개인정보 수집 방법</pre>
        <br></br>
        <p>- 홈페이지내 회원가입 및 로그인</p>

        <pre>2. 개인정보의 수집 및 이용목적</pre>
        <br></br>
        <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>

        <p>- 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산</p>
        <p>- 디스크 공간 제공, 구매 및 요금 결제, 결제 만료일 알림 SMS 등 발송</p>
        <pre>- 회원 관리</pre>
        <br></br>
        <p> - 가입 의사 확인, 불만 처리 등 민원 처리, 고지 사항 전달</p>
        <pre>- 마케팅 및 광고에 활용</pre>
        <br></br>
        <p> - 신규 서비스 개발 및 특화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</p>

        <pre>3. 개인정보의 보유 및 이용 기간</pre>
        <br></br>
        <p>원칙적으로 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p>

        <pre>(1) 회사 내부 방침에 의한 정보보유 사유</pre>
        <br></br>
        <p>- 부정이용기록보존 이유: 부정 이용 방지</p>
        <p>- 보존 기간: 1년</p>

        <pre>(2) 관련법령에 의한 정보보유 사유</pre>
        <br></br>
        <p>상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.</p>

        <p>이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.</p>

        <pre>- 웹사이트 방문기록</pre>
        <br></br>
        <p> - 보존 이유: 통신비밀보호법</p>
        <p> - 보존 기간: 3개월</p>
        <pre>- 본인확인에 관한 기록</pre>
        <br></br>
        <p> - 보존 이유: 정보통신망 이용촉진 및 정보보호 등에 관한 법률</p>
        <p> - 보존 기간: 6개월</p>
        <pre>- 소비자의 불만 또는 분쟁처리에 관한 기록</pre>
        <br></br>
        <p> - 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률</p>
        <p> - 보존 기간: 3년</p>
        <pre>- 계약 또는 청약철회 등에 관한 기록</pre>
        <br></br>
        <p> - 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률</p>
        <p> - 보존 기간: 5년</p>
        <pre>- 대금결제 및 재화 등의 공급에 관한 기록</pre>
        <br></br>
        <p> - 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률</p>
        <p> - 보존 기간: 5년</p>

        <p>개인정보 보호책임자</p>
        <p>회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제를 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
        <br/>

        <p>이　　　 름 : 조훈상</p>
        <p>소속 / 직위 : 텐마일즈 / 대표이사</p>
        <p>E-M A I L : tenmiles22@naver.com</p>
        <p>전 화 번 호 : 070-8694-0057</p>
      </div>
      <div className="mb-100"></div>
    </div>
  );
}
    
