
import { useEffect, useState } from 'react';
import React from 'react';
// import SuccessPopUp from '.../components/successPopUp';
import SuccessPopUp from '../../components/successPopUp';

// const data = [
//   { amount: '10000', paymentDate: '2022-01-01', shortUrl: 'url1', status: 'paid' },
//   { amount: '20000', paymentDate: '2022-02-01', shortUrl: 'url2', status: 'unpaid' },
//   // 추가 데이터...
// ];


export default function MyPage() {
  const [showPopup, setShowPopup] = useState(false); // Popup 표시 상태
  const [fetchedData, setFetchedData] = useState({
    email: '',
    phoneNumber: '',
    expiredDate: '',
  });
  const [bills, setBills] = useState([]); // 결제 내역 데이터
  const [editablePhoneNumber, setEditablePhoneNumber] = useState('');

  const formatDate = (dateString) => {
    if (!dateString) return '무료회원';
  
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };

  useEffect(() => {
    const fetchData = async () => {
      // accessToken을 이용하여 유저 정보를 가져옵니다.
      const accessToken = localStorage.getItem('accessToken');

      // console.log('accessToken:', accessToken);
      const response = await fetch('/api/getUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ key: 'value', accessToken: accessToken })
      });
      const data = await response.json();

      // 토큰이 만료되었는지 확인합니다.
      if (response.status === 401) {
        // 토큰이 만료되었다면, 사용자를 로그아웃시킵니다.
        localStorage.removeItem('accessToken');
        // 로그아웃 후에는 페이지를 리로드하거나 로그인 페이지로 리다이렉트하는 등의 추가적인 처리를 할 수 있습니다.
        // login 페이지로 리다이렉트합니다.
        window.location.href = '/login';


        return;
      }

      // console.log('유저 데이터:', data);
      try {
        const userData = {
          email: data[0].userEmail,
          phoneNumber: data[0].phone,
          expiredDate: formatDate(data[0].expiredDate) || '무료회원'
        };
        // console.log('유저 데이터:', userData);
        setFetchedData(userData);
        setEditablePhoneNumber(userData.phoneNumber); // 여기에서 설정
      } catch (error) {
        console.error('유저 데이터를 가져오는데 실패했습니다:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/getUserBills', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ userEmail: fetchedData.email })
        });
        let data = await response.json();
        // 데이터 변환
        data = data.map(item => {
          // 날짜 변환
          // console.log(item);
          const date = new Date(item.paymentDate);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hour = String(date.getHours()).padStart(2, '0');
          const minute = String(date.getMinutes()).padStart(2, '0');
          
          const formattedDate = `${year}년 ${month}월 ${day}일 ${hour}시 ${minute}분`;
          // 상태 변환
          let status;
          switch (item.status) {
            case 'F':
              status = '결제완료';
              break;
            case 'W':
              status = '미결제';
              break;
            case 'C':
              status = '취소';
              break;
            case 'D':
              status = '파기';
              break;
            default:
              status = item.status;
          }

          // 금액 변환
          const amount = item.amount.toLocaleString('ko-KR') + '원';

          return { ...item, paymentDate: formattedDate, status, amount };
        });

        setBills(data);
      } catch (error) {
        console.error('결제내역 데이터를 가져오는데 실패했습니다:', error);
      }
    };
    if (fetchedData.email) {
      fetchData();
    }
  }, [fetchedData.email]);

  const handlePhoneNumberChange = (e) => {
    setEditablePhoneNumber(e.target.value);
  };

  const handleUpdatePhoneNumber = async () => {
    
    // 여기서 수정된 휴대폰 번호를 처리합니다.
    // 예: API를 호출하여 서버에 저장
    // console.log('수정된 휴대폰 번호:', editablePhoneNumber);
    // 서버에 저장 후 fetchedData를 업데이트할 수 있습니다.
    // email 을 기준으로 phoneNumber를 업데이트합니다.
    setFetchedData({
      ...fetchedData,
      phoneNumber: editablePhoneNumber
    });
    // console.log('수정된 휴대폰 번호:', editablePhoneNumber);
    // console.log('수정된 휴대폰 번호:',fetchedData.email);
    // //api호출 /api/updateUserPhoneNumber
    try {
      const response = await fetch('/api/updateUserPhoneNumber', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ 
          email: fetchedData.email,
          phoneNumber: editablePhoneNumber })
      });
      const data = await response.json();
      // console.log('유저 데이터:', data);

      setShowPopup(true); // API 호출 성공 후 Popup 표시
      setTimeout(() => setShowPopup(false), 3000); // 3초 후 Popup 숨김
    } catch (error) {
      console.error('유저 데이터를 가져오는데 실패했습니다:', error);
    }
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900">마이페이지</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">기본정보 및 결제 정보입니다.</p>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">이메일</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{fetchedData.email}</dd>
            <span className="text-xs text-red-500">
                *이메일은 수정 불가합니다.
              </span>
          </div>

    
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">휴대폰번호</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={editablePhoneNumber}
                onChange={handlePhoneNumberChange}
                className="w-full rounded-md border-2 border-green-500 bg-white px-2 py-2 text-xl shadow-lg focus:border-indigo-500 focus:ring-2 focus:ring-gray-500"
              />
            </dd>
              <span className="text-xs text-red-500">
                "-" 없이 휴대폰번호를 입력해주세요. 결제시 이 번호로 반영됩니다. 수정 후 수정완료버튼을 눌러주세요.
              </span>

          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">유료회원 만료기간</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{fetchedData.expiredDate}</dd>
          </div>
        </dl>
      </div>
      {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">결제 내역</dt>
        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
              <div className="flex w-0 flex-1 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                </svg>
                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                  <span className="truncate font-medium">resume_back_end_developer.pdf</span>
                  <span className="flex-shrink-0 text-gray-400">2.4mb</span>
                </div>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Download
                </a>
              </div>
            </li>
            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
              <div className="flex w-0 flex-1 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                </svg>

                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                  <span className="truncate font-medium">coverletter_back_end_developer.pdf</span>
                  <span className="flex-shrink-0 text-gray-400">4.5mb</span>
                </div>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Download
                </a>
              </div>
            </li>
          </ul>
        </dd>

      </div> */}
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">결제 내역</dt>
        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  결제상품명
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  금액
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  결제상태
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  결제내역
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  결제일
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {bills.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.productNm}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.amount}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.status}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <a href={item.shortUrl} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">
                      청구서/영수증
                    </a>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.paymentDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </dd>
      </div>


      <div className="px-4 py-6 sm:flex sm:justify-center sm:gap-4 sm:px-6">
        {/* <button
          type="button"
          className="rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600"
          onClick={() => { window.open('https://coudaemonster.channel.io/home', '_blank'); }}
        >
          환불문의
        </button> */}
        <button
          type="button"
          className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
          onClick={() => { window.location.href = '/payment'; }}
        >
          연장하기
        </button>
        <button
          type="button"
          className="rounded-md bg-yellow-50 px-3.5 py-2.5 text-sm font-semibold text-yellow-600 shadow-sm hover:bg-yellow-100"
          onClick={() => { window.location.href = '/payment'; }}
        >
          결제하기
        </button>
        <button
        type="button"
        className="rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600"
        onClick={handleUpdatePhoneNumber}
      >
        수정완료
      </button>
      <div>
      {/* ... 페이지 내용 ... */}
      {showPopup && <SuccessPopUp message="휴대폰 번호가 수정되었습니다." />}
    </div>
      </div>
    </div>
  )
}