import React from 'react';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog } from '@headlessui/react'
import Banner from '../../components/Banner';

const navigation = [
  { name: '다운로드', href: '/' },
  { name: '매뉴얼', href: 'https://www.notion.so/1797afa1e8c442cdae20f979cc18679f?pvs=4' },
  { name: '쿠대', href: 'https://admin.coudae.kr/#/login' },
  // { name: '마이페이지', href: '/mypage' },
  // { name: '요금제', href: '/pricing' },
  // { name: '회원가입', href: '/signup' },
  { name: '회원관리', href: '/team' },
]

const Topbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const isLoggedIn = () => {
    return localStorage.getItem('accessToken') !== null;
  };

  // const isAdmin = () => {
  //   const level = localStorage.getItem('level');
  //   const result = level === '98';
  //   console.log('관리자 여부:', result,level);
  //   return result;
  // };
  const isAdmin = () => {
    const level = localStorage.getItem('level');
    return level === '98'; // 문자열 '98'과 동일할 때만 true 반환
  };

  return (
    <header className="bg-white">
      {/* <Banner /> */}
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5" onClick={() => navigate('/')}>
            <span className="sr-only">쿠대몬스터</span>
            <img className="h-8 w-auto" src="../../assets/coudaemonster.png" alt="logo" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        
        <div className="hidden lg:flex lg:gap-x-12 flex-wrap">
 {navigation.map((item) => {
   // 로그인 상태일 때만 '마이페이지' 링크 표시
   // if (item.name === '마이페이지' && !isLoggedIn()) {
   //   return null;
   // }
   // if (item.name === '회원가입' && isLoggedIn()) {
   //   return null;
   // }
   // 로그인 상태이고, 관리자일 경우에만 '회원관리' 탭 표시
   if (item.name === '회원관리' && (!isLoggedIn() || !isAdmin())) {
     return null;
   }
   return (
     <a
       key={item.name}
       href={item.href}
       className="text-xl font-semibold leading-6 text-gray-900 mb-4 lg:mb-0"
       target={item.name === '매뉴얼' ? "_blank" : "_self"}
       rel={item.name === '매뉴얼' ? "noopener noreferrer" : ""}
     >
       {item.name}
     </a>
   );
 })}
</div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {/* {isLoggedIn() ? (
            // 로그인 상태일 때 로그아웃 버튼 표시
            <button onClick={handleLogout} className="text-xl font-semibold leading-6 text-gray-900">
              로그아웃 <span aria-hidden="true">&rarr;</span>
            </button>
          ) : (
            // 로그인 상태가 아닐 때 로그인 링크 표시
            <a href="/login" className="text-xl font-semibold leading-6 text-gray-900">
              로그인 <span aria-hidden="true">&rarr;</span>
            </a>
          )} */}
        </div>

      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
          <a href="#" className="-m-1.5 p-1.5" onClick={() => navigate('/')}>
            <span className="sr-only">쿠대몬스터</span>
            <img className="h-8 w-auto" src="../../assets/coudaemonster.png" alt="logo" />
          </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="space-y-2 py-6">
            {navigation.map((item) => {
              // 로그인 상태일 때만 '마이페이지' 링크 표시
              // if (item.name === '마이페이지' && !isLoggedIn()) {
              //   return null;
              // }
              // if (item.name === '회원가입' && isLoggedIn()) {
              //   return null;
              // }
              // 로그인 상태이고, 관리자일 경우에만 '회원관리' 탭 표시
              if (item.name === '회원관리' && (!isLoggedIn() || !isAdmin())) {
                return null;
              }
              // if (item.name === '회원관리' && (isLoggedIn() || isAdmin())) {
              //   return null;
              // }
              return (
                <a 
                key={item.name} 
                href={item.href} 
                className="text-xl font-semibold leading-6 text-gray-900"
                target={item.name === '매뉴얼' ? "_blank" : "_self"}
                rel={item.name === '매뉴얼' ? "noopener noreferrer" : ""}
              >
                {item.name}
              </a>
              );
            })}
            {/* 로그인/로그아웃 버튼 추가 */}
            {/* {isLoggedIn() ? (
              <button
                onClick={handleLogout}
                className="mt-6 w-full rounded-lg bg-gray-100 px-4 py-2 text-left text-base font-semibold leading-7 text-gray-900"
              >
                로그아웃
              </button>
            ) : (
              <a
                href="/login"
                className="mt-6 w-full block rounded-lg bg-gray-100 px-4 py-2 text-left text-base font-semibold leading-7 text-gray-900"
              >
                로그인
              </a>
            )} */}
          </div>

        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

export default Topbar;
