
import { useEffect, useState } from 'react';
import React from 'react';
import SuccessPopUp from '../../components/successPopUp';
import FailedPopUp from '../../components/FailedPopUp';

export default function PaymentPage() {

  const [failedShowPopup, setfailedShowPopup] = useState(false); // Popup 표시 상태
  const [successShowPopup, setsuccessShowPopup] = useState(false); // Popup 표시 상태
  const [fetchedData, setFetchedData] = useState({
    email: '',
    phoneNumber: '',
    finalAmount: '', // 최종 결제 금액 상태
  });

  useEffect(() => {
    const fetchData = async () => {
      // console.log('유저 정보 찾기');
      const accessToken = localStorage.getItem('accessToken');
      
      try {
        const response = await fetch('/api/getUser', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ key: 'value', accessToken: accessToken })
        });
        const data = await response.json();
        // console.log('->>>',data[0]);
        setFetchedData({
          email: data[0].userEmail,
          phoneNumber: data[0].phone,
          finalAmount: "220,000원 (부가세 포함)"
        });
      } catch (error) {
        console.error('유저 데이터를 가져오는데 실패했습니다:', error);
      }
    };

    fetchData();
  }, []);


  const handlePaymentRequest = async () => {
    console.log("Payment requested");
    console.log(fetchedData);

    try {
      const response = await fetch('/api/payteacher/sendBill', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(fetchedData) // 요청 본문
      });

      if (!response.ok) {
        throw new Error('Payment request failed');
      }

      const data = await response.json();
      console.log('Payment response:', data);

      if (data.code !== "0000") {
        throw new Error('Payment request failed');
      }

      setsuccessShowPopup(true); // API 호출 성공 후 Popup 표시

      // 결제 요청 성공 후 5초 후에 '/mypage'로 이동
      setTimeout(() => {
        window.location.href = '/mypage';
      }, 5000);

    } catch (error) {
      console.error('Payment request error:', error);
      setfailedShowPopup(true); // API 호출 실패 후 Popup 표시
    }
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">결제 페이지</h3>
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {/* 결제내용 */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-lg font-bold text-gray-900">결제내용</dt>
              <dd className="mt-1 text-xl font-bold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">쿠대몬스터 유료회원 30일 결제</dd>
            </div>
            {/* 이메일 */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-lg font-bold text-gray-900">이메일</dt>
              <dd className="mt-1 text-xl font-bold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{fetchedData.email}</dd>
            </div>
            {/* 휴대폰번호 */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-lg font-bold text-gray-900">휴대폰번호</dt>
              <dd className="mt-1 text-xl font-bold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {fetchedData.phoneNumber} <br />
              </dd>
               <br />
                <a href="/mypage" className="text-blue-600 hover:text-blue-800">
                위의 휴대폰번호로 결제요청 카카오톡이 전송됩니다. <br />
                휴대폰번호를 변경하시려면 여기를 눌러주세요
                </a>
            </div>
            {/* 최종결제금액 */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-lg font-bold text-gray-900">최종결제금액</dt>
              <dd className="mt-1 text-xl font-bold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{fetchedData.finalAmount}</dd>
            </div>
          </dl>
        </div>
    </div>
      {/* 유의사항 */}
      <div className="px-4 py-6 sm:px-6">
        <h4 className="text-base font-semibold leading-7 text-gray-900">유의사항</h4>
        <p className="mt-1 text-sm leading-6 text-gray-500">결제와 관련된 주요 사항을 확인해주세요.</p>
        <p>※ 카드결제는 입력하신 연락처(휴대전화)의 카카오톡으로 결제신청서가 전송이 됩니다.</p>
        <p>※ 입력하신 연락처(휴대전화)가 카카오톡으로 가입이 되지 않았을 경우 결제가 불가능합니다.</p>
        <p>※ 비대면 결제대행 서비스 회사인 '결제선생'이 카드결제 신청서를 카카오톡으로 보내드립니다.</p>
        <p>※ 카드결제가 확인되면 자동으로 권한을 드린 후 입력하신 문자로 알려드립니다.</p>
        <p>※ 카드결제는 세금계산서를 발행하지 않습니다. 세금계산서 발행 시 이중과세에 해당합니다. (부가세법 시행령 제57조 2항)</p>
        {/* 빨간 글씨 */}
        <p className="mt-1 text-sm leading-6 text-red-500">※ 결제 후 30일간 유료회원으로 자동 전환됩니다.</p>
        <p className="mt-1 text-lg leading-6 text-red-500">※ 30일 결제 일수 기준 사용기간이 5일 이내면 환불금액 10만원 입니다.</p>
        <p className="mt-1 text-lg leading-6 text-red-500">※ 결제일 기준 사용기간이 5일 이후(6일 이상) 환불 불가합니다. </p>
      
      </div>
      {/* 결제요청 버튼 */}
      {/* <div className="px-4 py-6 sm:flex sm:justify-center sm:gap-4 sm:px-6">
        <button
        type="button"
        className="rounded-md bg-blue-500 px-3.5 py-2.5 text-xl font-semibold text-white shadow-sm hover:bg-blue-600"
        onClick={handlePaymentRequest}
        >
        결제요청
        </button>
      </div> */}
      <p className="mt-1 text-lg leading-6 text-red-500">※ 신규결제가 불가합니다. </p>
    <div>
      {/* ... 페이지 내용 ... */}
      {failedShowPopup && 
        <FailedPopUp 
          title="발송실패" 
          message={
            <>
              결제요청에 실패했습니다. <br />
              올바른 휴대전화번호인지 확인해주세요. <br />
              <br />
              새로고침 후 재요청에도 이 문구가 계속 된다면 문의해주세요.
            </>
          } 
        />
      }
      {successShowPopup && 
        <SuccessPopUp 
          title="결제요청" 
          message={
            <>
              성공적으로 결제요청을 보냈습니다. <br />
              카카오톡에서 '결제선생'을 확인해주세요. <br />
              <br />
              3초뒤 마이페이지로 이동합니다. <br />
              결제 후 만료기간을 확인해주세요.
            </>
          } 
        />
      }
    </div>
    </div>
  );
}