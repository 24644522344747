import axios from 'axios';
import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { CheckIcon,ArrowDownIcon } from '@heroicons/react/20/solid'
import SuccessPopUp from '../../components/successPopUp';
import FooTer from '../global/footer';
import SystemRequirements from '../../components/requirecomputer';
// ...
const faqs = [
  {
    question: '상품수 제한 , 마켓수 제한, 수집 제한 없나요?',
    answer: "쿠대유료결제자는 별도의 결제 없이 수집 및 업로드 수량에 따른 추가 요금 없이 무한으로 사용 가능합니다.",
  },
  {
    question: '로컬 PC 기반인가요?',
    answer:
      '네, 로컬 PC 기반으로 운영됩니다. 더욱 안정적입니다.',
  },
  {
    question: "로컬 기반이니 수집한 PC 내에서만 업로드가 가능한 건가요? ",
    answer: "맞습니다, PC 내에서만 사용이 가능합니다. 다른 컴퓨터로 옮길 시 정보가 사라지니 주의하시기 바랍니다."
  },
  {
    question: "다른 PC에서 로그인하면 새로 수집을 해야 하나요? 포맷하면 정보가 날라가나요?",
    answer: "안심하세요. 백업 기능을 통해 데이터를 보호할 수 있습니다. 백업을 진행한 후에는 포맷 후에도 쉽게 다시 설정하고 이용하실 수 있습니다. 단, 서버에 백업되지 않으니 이 점을 유의해 주시기 바랍니다."
  },
  {
    question: "프로그램 여러 개 실행시켜서 동시 수집이 가능한가요?",
    answer: "네, 확실히 가능합니다. 대량 수집을 위해 여러 프로그램을 동시에 실행하는 것도 가능합니다."
  },
  {
    question: "쿠대몬스터 대량 프로그램 강의도 있나요?",
    answer: "그렇습니다, 쿠대몬스터의 대량 프로그램 강의가 기다리고 있습니다. 기초 강의에서 한 단계 업그레이드된 '대량 ALL IN ONE' 강의를 준비했습니다. 강의 일정 및 상세한 정보는 곧 열릴 설명회에서 자세히 안내해 드릴 예정입니다."
  }

]
const tiers = [
  // {
  //   name: '무료',
  //   id: 'tier-hobby',
  //   href: '/',
  //   priceMonthly: '0원',
  //   description: '부담없이 시작해보세요.',
  //   features: [
  //     '설치 비용 : 무료',
  //     '상품 수집 : 무제한',
  //     '마켓 계정 : 무제한',
  //     '상품 등록 : 10,000개',
  //     '소싱처 : 알리익스프레스',
  //     '멀티탭(중복프로그램) : 최대 1개',
  //     '연동 마켓 : 쿠팡',
  //   ],
  // },
  {
    isPremium: true,
    name: '유료',
    id: 'tier-team',
    href: '/payment',
    priceMonthly: '200,000원',
    description: '제한없이 사용하세요.',
    features: [
      '설치 비용 : 무료',
      '상품 수집 : 무제한',
      '마켓 계정 : 무제한',
      '상품 등록 : 무제한',
      '소싱처 : 알리익스프레스 , 아마존 , 도매매, 소싱처 개발중..',
      '멀티탭(중복프로그램) : 최대 2개(수집,업로드 포함)',
      '연동 마켓 : 스마트스토어 , 쿠팡 , 11번가 , 지마켓 , 옥션',
    ],
  },
]
const features = [
  {
    name: '실제 사용자들의 만족도가 높은 증거',
    description: '사용자들의 긍정적인 리뷰와 매출 증대 사례가 이를 입증합니다.',
  },
  {
    name: '사용의 용이성',
    description: '쿠대몬스터는 모든 사용자가 쉽게 접근할 수 있도록 설계되었습니다. 초보자도 매뉴얼을 통해 쉽게 프로그램을 사용할 수 있으며, 지속적인 업데이트로 최신 트렌드를 반영합니다.',
  },
  {
    name: '경제적인 요금제',
    description: '쿠대유료결제자는 별도의 결제 없이 수집 및 업로드 수량에 따른 추가 요금 없이 무한으로 사용 가능합니다.',
  },
  {
    name: '전문가들이 제공하는 최고의 서비스',
    description: '쿠대몬스터는 경력 20년 이상의 고급 개발자들이 개발하였으며, 안정적인 운영과 끊임없는 기술 개발로 시장에서 인정받고 있습니다.',
  },
];

export default function Dashboard() {
  const [successShowPopup, setsuccessShowPopup] = useState(false); // Popup 표시 상태

  async function versionCheck(repo) {
    const OWNER = 'zerowater-may';
    const API_SERVER_URL = `https://api.github.com/repos/${OWNER}/${repo}`;
    const MY_API_KEY = process.env.REACT_APP_GITHUB_API_KEY;
    const resUrl = `${API_SERVER_URL}/releases/latest`;
    // console.log(resUrl);
    try {
      const res = await axios.get(resUrl, {
        auth: {
          username: OWNER,
          password: MY_API_KEY
        }
      });
      // console.log(res.data);

      if (res.status !== 200) {
        return false;
      }

      return res.data;
    } catch (error) {
      console.error('Version check failed:', error);
      return false;
    }
  }
  // const navigate = useNavigate();
  const [version, setVersion] = useState('1.0.0'); // 예시 버전 상태
  const [openIndex, setOpenIndex] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null); // 다운로드 링크 상태 추가

  useEffect(() => {
    // 버전 체크
    versionCheck('coudaemonsterdownload').then((res) => {
      if (res && res.tag_name) {
        setDownloadLink(res.zipball_url); // 다운로드 링크를 상태에 저장
        setVersion(res.tag_name); // 태그 이름을 저장
      } else {
        console.error('버전 데이터를 가져오는데 실패했습니다.');
      }
    });
  }, []);

  const handleDownload = () => {
    // const accessToken = localStorage.getItem('accessToken');
    // if (!accessToken) {
    //   setsuccessShowPopup(true);
    //   // navigate('/login');
    //   return;
    // }

    // if (!version || !downloadLink) {
    //   console.error('Version data or download link is not available');
    //   return;
    // }

    // window.location.href = downloadLink; // 다운로드 링크로 이동
    window.open('https://www.notion.so/e2d84d6d7cc2469dbdd8dd10ecd94c0d?pvs=4', '_blank');
  };

  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <img
                  className="h-30"
                  src={`../../assets/coudaemonster.png`}
                  alt="쿠대몬스터 로고"
                />
                { }
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  현존 최강
                  <br />
                  대량등록 솔루션
                </h1>
                <h2 className="mt-6 text-2xl font-bold tracking-tight text-red-900 sm:text-3xl">
                  이제 그만 추가비용을 지불하고
                  <br />
                  불편한 대량등록을 하지 마세요.
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">

                  쿠대몬스터는 귀하의 비즈니스 성장을 가속화하는 대량등록 솔루션입니다. 효율적인 대량 상품 업로드와 관리가 가능합니다.
                  <br />
                  단, 한번의 결제로 모든 서비스를 무제한 이용할 수 있습니다.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <button
                    onClick={handleDownload}
                    style={{ backgroundColor: '#16357c', padding: '20px', fontSize: '20px' }} // padding과 font-size 조정
                    className="rounded-md text-base font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                  >
                    쿠대몬스터 엄청 빠른 다운로드
                  </button>
                  <a href="https://www.notion.so/1797afa1e8c442cdae20f979cc18679f?pvs=4" target="_blank" rel="noopener noreferrer" className="text-base font-semibold leading-6 text-gray-900">
                    매뉴얼 <span aria-hidden="true">→</span>
                  </a>
                </div>

              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-indigo-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                  <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                    <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                      <img
                        src="https://coudaesongsong.s3.ap-northeast-2.amazonaws.com/coudaemonster_main2.png"
                        // src={`../../assets/coudaemonster_screenshot1.png`}
                        alt="App screenshot"
                        width={2432}
                        height={1442}
                        className="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
                      />
                    </div>
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
      <div className="mx-auto mt-10 max-w-7xl px-6 lg:px-8">
      {/* 이벤트 문구 추가 */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 flex justify-center">
  <div className="max-w-md grid grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-1">
    {tiers.map((tier) => (
      <div
        key={tier.id}
        className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
      >
        <div>
          {/* 유료 요금제일 경우 이벤트 문구 표시 */}
          {/* {tier.isPremium && (
            <p className="text-center text-lg font-bold text-red-600 mb-4">
              <span className="text-2xl">🎁</span> 30일 더! <br />
              *선착순 100명 한정
            </p>
          )} */}
          {/* <h4 id={tier.id} color="#16357c" className="text-base font-semibold leading-7 text-indigo-600">
            {tier.name}
          </h4> */}
          <div className="mt-4 flex items-baseline gap-x-2">
            <span className="text-3xl font-bold tracking-tight text-gray-900">
              기능 소개
            </span>
            {/* <span className="text-base font-semibold leading-7 text-gray-600">
              /월
            </span> */}
          </div>
          <p className="mt-6 text-base leading-7 text-gray-600">
            {/* {tier.description} */}
          </p>
          <ul className="space-y-4 text-sm leading-6 text-gray-600">
            {tier.features.map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <CheckIcon
                  className="h-6 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                {feature}
              </li>
            ))}
          </ul>
        </div>
        {/* <button
          onClick={() => handleButtonClick(tier.name)}
          style={{ backgroundColor: "#16357c", padding: "10px", fontSize: "20px" }}
          aria-describedby={tier.id}
          className="mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          시작하기
        </button> */}
        {/* 팝업 창 (로그인 안내 등) */}
        {/* <PopUp open={openDialog} setOpen={setOpenDialog} /> */}
      </div>
    ))}
  </div>
</div>
        <SystemRequirements/>
      </div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-indigo-600">해외부터 국내까지</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">올인원 대량등록 프로그램</p>
              <p className="mt-6 text-base leading-7 text-gray-600">
                쿠대몬스터는 귀하의 비즈니스 성장을 가속화하는 대량등록 솔루션입니다. 효율적인 대량 상품 업로드와 관리가 가능합니다.
              </p>
            </div>
            <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                    {feature.name}
                  </dt>
                  <dd className="mt-2">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="lg:col-span-5">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                  자주 묻는 질문
                </h2>
                <p className="mt-4 text-base leading-7 text-gray-600">
                  여기서 궁금한 점에 대한 답변을 찾아보세요. 
                  <br/>
                  찾지 못하셨다면 좌측 하단의 채팅 버튼을 통해 문의해주세요.
                </p>
              </div>
              <div className="mt-10 lg:col-span-7 lg:mt-0">
              <dl className="space-y-10">
                {faqs.map((faq, index) => (


                  <div key={faq.question}>
                    <dt 
                      className="flex items-center text-base font-semibold leading-7 text-gray-900 cursor-pointer hover:text-blue-600" 
                      onClick={() => setOpenIndex(openIndex === index ? null : index)}
                    >
                    <ArrowDownIcon className="left-0 top-1 h-5 w-5 text-indigo-500 mr-2" aria-hidden="true" />
                      {/* {openIndex === index ? <ArrowDownIcon size={20} /> : <ArrowDownIcon size={20} />} */}
                      
                      {faq.question}
                    </dt>
                    {openIndex === index && (
                      <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                    )}
                  </div>
                ))}
              </dl>
              </div>
            </div>
            <div className="mt-20 text-center">
              {/* <SystemRequirements /> */}
            </div>
          </div>
        </div>
        {successShowPopup &&
          <SuccessPopUp
            title="쿠대몬스터 다운로드"
            message={
              <>
                로그인 후 엄청 빠르게 다운로드 할 수 있습니다.
                <br />
              </>
            }
            link="/login"
            linkText="로그인 하러 가기"
          />
        }
      </div>
      <FooTer />
    </div>

  )
}
