import React from 'react';

const SystemRequirements = () => {
  return (
  <>      
    <div>
      <h1 class="text-center text-3xl font-bold mt-10">
        시스템 요구사항
      </h1>
      {/* description */}
    </div>
      <p class="text-center text-lg mt-4">
        쿠대몬스터는 다음과 같은 최소 및 권장 사양을 요구합니다.
      </p>
    <div class="relative overflow-x-auto flex justify-center mt-10">
      <table class="min-w-1/2 text-sm text-left rounded-xl rtl:text-right text-gray-500 dark:text-gray-400 ">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        구분
                    </th>
                    <th scope="col" class="px-6 py-3">
                        최소사양
                    </th>
                    <th scope="col" class="px-6 py-3">
                        권장 사양
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        OS
                    </th>
                    <td class="px-6 py-4">
                        Windows 10 64-bit 이상<br />
                        MAC : SONOMA 자동업데이트 지원 불가 <br />
                        (Boot Camp , parallels 지원)
                    </td>
                    <td class="px-6 py-4">
                        Windows 10 64-bit 이상<br />
                        MAC : SONOMA 자동업데이트 지원 불가 <br />
                        (Boot Camp , parallels 지원)
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        CPU
                    </th>
                    <td class="px-6 py-4">
                        intel Core i5-4430, AMD FX-6300
                    </td>
                    <td class="px-6 py-4">
                        intel Core i5-6600K, AMD Ryzen 5 1600
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        RAM
                    </th>
                    <td class="px-6 py-4">
                        8 GB (멀티탭 사용시 8GB 불가 버벅임 발생 가능성 있음)
                    </td>
                    <td class="px-6 py-4">
                        16 GB (멀티탭 사용시 32GB 이상 권장)
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    VGA
                  </th>
                  <td class="px-6 py-4">
                    NVidia: GeForce 9600GT<br />
                    AMD: HD 6570<br />
                    Intel: Intel HD 4600 내장 그래픽<br />
                    (비디오 메모리 1GB 이상)
                  </td>
                  <td class="px-6 py-4">
                    NVidia: GeForce 560<br />
                    AMD: HD 6950<br />
                    Intel: Intel UHD 630 내장 그래픽<br />
                    (비디오 메모리 2GB 이상)
                  </td>
                </tr>
                <tr class="bg-white dark:bg-gray-800">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        DirectX
                    </th>
                    <td class="px-6 py-4">
                        DirectX 11 이상
                    </td>
                    <td class="px-6 py-4">
                        DirectX 11 이상
                    </td>

                </tr>
                <tr class="bg-white dark:bg-gray-800">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    여유 저장 공간(HDD , SSD)
                    </th>
                    <td class="px-6 py-4">
                      HDD 16GB 이상
                    </td>
                    <td class="px-6 py-4">
                      상품 수집 수량에 따라 다름
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    </>
  );
}

export default SystemRequirements;
