import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
// import React, { useState } from 'react';
import { useEffect, useState } from 'react';
import React from 'react';
// import SuccessPopUp from '.../components/successPopUp';
import SuccessPopUp from '../../components/successPopUp';

// const data = [
//   { amount: '10000', paymentDate: '2022-01-01', shortUrl: 'url1', status: 'paid' },
//   { amount: '20000', paymentDate: '2022-02-01', shortUrl: 'url2', status: 'unpaid' },
//   // 추가 데이터...
// ];


export default function AdminPaymentPage({userPaymentData}) {
  // console.log('userPaymentData',userPaymentData);
  const [open, setOpen] = useState(false);
  const [billIdToCancel, setBillIdToCancel] = useState(null);
  const [amountToCancel, setAmountToCancel] = useState(null);
  // const [fetchData, setFetchData] = useState({
  //   email: '',
  //   expiredDate: '',
  // });

  const handleOpenDialog = (billId, amount) => {
    setBillIdToCancel(billId);
    setAmountToCancel(amount);
    setOpen(true);
  };
  
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const [bills, setBills] = useState([]); // 결제 내역 데이터

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = userPaymentData;
        // 날짜 오름차순 정렬합니다
        // 만약 정렬가능하지 않는다면 넘어가는 코드
        
        // data.sort((a, b) => {
        //   return new Date(b.paymentDate) - new Date(a.paymentDate);
        // });
        data = data.map(item => {
          const date = new Date(item.paymentDate);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hour = String(date.getHours()).padStart(2, '0');
          const minute = String(date.getMinutes()).padStart(2, '0');
          
          const formattedDate = `${year}년 ${month}월 ${day}일 ${hour}시 ${minute}분`;
          let status;
          switch (item.status) {
            case 'F':
              status = '결제완료';
              break;
            case 'W':
              status = '미결제';
              break;
            case 'C':
              status = '취소';
              break;
            case 'D':
              status = '파기';
              break;
            default:
              status = item.status;
          }

          const amount = item.amount.toLocaleString('ko-KR') + '원';

          return { ...item, paymentDate: formattedDate, status, amount };
        });

        setBills(data);
      } catch (error) {
        console.error('결제내역 데이터를 가져오는데 실패했습니다:', error);
      }
    };

    fetchData(); // fetchData 함수를 호출합니다.
  }, [userPaymentData]); // userPaymentData가 변경될 때마다 useEffect 훅을 실행합니다.
  
  const [showPopup, setShowPopup] = useState(false); // 팝업 표시 상태
  const [popupMessage, setPopupMessage] = useState(''); // 팝업 메시지

  const handleConfirmCancelPayment = async () => {
    try {
      const response = await fetch('/api/payteacher/cancelBill', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          billId: billIdToCancel,
          amount: amountToCancel
        })
      });
      const data = await response.json();
      console.log('결제취소 요청:', data);
  
      // 결제 취소 성공 메시지 설정 및 팝업 표시
      setPopupMessage('결제 취소가 성공적으로 처리되었습니다.');
      setShowPopup(true);
  
      // 다이얼로그 닫기
      setOpen(false);
  
    } catch (error) {
      console.error('결제취소 요청에 실패했습니다:', error);
  
      // 결제 취소 실패 메시지 설정 및 팝업 표시
      setPopupMessage('결제 취소 처리에 실패했습니다.');
      setShowPopup(true);
      
      // 다이얼로그 닫기
      setOpen(false);
    }
  };
  
  
  const handleCancelPayment = async (billId, amount) => {
    handleOpenDialog(billId, amount);
    console.log('결제취소 요청:', billId);
    console.log('결제취소 요청:', amount);
  };



  return (
    
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div>
    {showPopup && (
      <SuccessPopUp message={popupMessage} onClose={() => setShowPopup(false)} />
    )}
  </div>
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-black-900">마이페이지</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-black-500">기본정보 및 결제 정보입니다.</p>
      </div>
      <div className="border-t border-black-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-black-900">이메일</dt>
            <dd className="mt-1 text-sm leading-6 text-black-700 sm:col-span-2 sm:mt-0">{bills[0]?.userEmail || ''}</dd>
            <span className="text-sm text-red-500">
              *이메일은 수정 불가합니다.

            </span>
          </div>
        </dl>
      </div>
      <span className="text-xl text-red-500">
        * 결제취소하고 "예" 누르면 취소된겁니다. 새로고침해서 결제상태를 확인해주세요.
      </span>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <table className="min-w-full divide-y divide-gray-500">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider">
                  결제상품명
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider">
                  금액
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider">
                  결제상태
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider">
                  결제내역
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider">
                  결제일
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  결제취소
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-500">
              {bills.length > 0 ? (
                bills.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">{item.productNm}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">{item.amount}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">{item.status}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">
                      <a href={item.shortUrl} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">
                        청구서/영수증
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">{item.paymentDate}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        type="button"
                        className="rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600"
                        onClick={() => handleCancelPayment(item.billId, item.amount)}
                      >
                        결제취소
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500" colSpan="6">데이터 없음</td>
                </tr>
              )}
            </tbody>
          </table>
        </dd>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
        >
          <DialogTitle>{"결제 취소"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              정말 결제를 취소하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>
              아니오
            </Button>
            <Button onClick={async () => {
              await handleConfirmCancelPayment();
              handleCloseDialog();
            }} autoFocus>
              예
            </Button>
          </DialogActions>
        </Dialog>
      </div>


      <div className="px-4 py-6 sm:flex sm:justify-center sm:gap-4 sm:px-6">
        {/* <button
          type="button"
          className="rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600"
          onClick={() => { window.open('https://coudaemonster.channel.io/home', '_blank'); }}
        >
          환불문의
        </button> */}
      <div>
      {/* ... 페이지 내용 ... */}
      {showPopup && (
  <div className="popup">
    <p>{popupMessage}</p>
    <button onClick={() => setShowPopup(false)}>닫기</button>
  </div>
)}
    </div>
      </div>

    </div>
  )
}