
import { useEffect, useState } from 'react';
import React from 'react';
// import SuccessPopUp from '.../components/successPopUp';
// import SuccessPopUp from '../../components/successPopUp';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function AdminDetailPage({userData}) {
    // 새로운 필드들에 대한 상태 정의
  const [startDate, setStartDate] = useState(new Date());

  const [level, setLevel] = useState('');
  const [username, setUsername] = useState('');
  const [psword, setPsword] = useState('');
  const [fullname, setFullname] = useState('');
  const [token, setToken] = useState('');
  const [vendorId, setVendorId] = useState('');
  // const [billId, setBillId] = useState('');
  const [amount, setAmount] = useState('');
  const [memo, setMemo] = useState('');
  // const [showPopup, setShowPopup] = useState(false);
  const [fetchedData, setFetchedData] = useState({
    email: '',
    phoneNumber: '',
    expiredDate: '',
  });

  const [editablePhoneNumber, setEditablePhoneNumber] = useState('');

  // formatDate 함수 정의
  const formatDate = (dateString) => {
    if (!dateString) {
      // 현재 날짜에서 7일을 뺀 날짜를 반환
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return new Intl.DateTimeFormat('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);
    }
  
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };

  useEffect(() => {
    console.log('유저 데이터:', userData);
    if (userData && userData.length > 0) {
      const user = userData[0];

      if (user.expiredDate) {
        // 만료 날짜가 있는 경우, 해당 날짜를 사용
        setStartDate(new Date(user.expiredDate));
      } else {
        // 만료 날짜가 없는 경우 (무료 회원), 현재 날짜에서 7일을 뺀 날짜를 사용
        const date = new Date();
        date.setDate(date.getDate() - 7);
        setStartDate(date);
      }
      setFetchedData({
        email: user.userEmail,
        phoneNumber: user.phone,
        expiredDate: formatDate(user.expiredDate) // 여기서 formatDate 함수를 사용
      });
      setEditablePhoneNumber(user.phone);
  
      // 각 필드를 상태에 설정
      setLevel(user.level);
      setUsername(user.username);
      setPsword(user.psword);
      setFullname(user.fullname);
      setToken(user.token);
      setVendorId(user.vendorId);
      // setBillId(user.bill_id);
      setAmount(user.amount);
      setMemo(user.memo);
    }
  }, [userData]);

  const handlePhoneNumberChange = (e) => {
    setEditablePhoneNumber(e.target.value);
  };

  const handleUpdateUserAllInfo = async () => {
    // 모든 정보를 UPDATE합니다 .
    const result = {
      email: fetchedData.email,
      phoneNumber: editablePhoneNumber,
      expiredDate: startDate,
      level,
      username,
      psword,
      fullname,
      vendorId,
      amount,
      memo
    }
    console.log(result);
    const response = await fetch('/api/updateUserAllInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(result)
    });
    const data = await response.json();
    console.log('유저 데이터:', data);
  };

  return (
    
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900">회원정보수정</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">회원정보수정 페이지입니다.</p>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">이메일</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{fetchedData.email}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">휴대폰번호</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={editablePhoneNumber}
                onChange={handlePhoneNumberChange}
                className="w-full rounded-md border-2 border-green-500 bg-white px-4 py-2 text-lg shadow-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
              <span className="text-xs text-red-500">
                "-" 없이 휴대폰번호를 입력해주세요. 결제시 이 번호로 반영됩니다. 수정 후 수정완료버튼을 눌러주세요.
              </span>
            </dd>
          </div>
          {/* username 입력 필드 */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">유저명</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={username}
                onChange={e => setUsername(e.target.value)}
                className="w-full rounded-md border-2 border-gray-300 px-4 py-2 text-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
            </dd>
          </div>

          {/* psword 입력 필드 */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">비밀번호</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={psword}
                onChange={e => setPsword(e.target.value)}
                className="w-full rounded-md border-2 border-gray-300 px-4 py-2 text-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
            </dd>
          </div>
          {/* psword 입력 필드 */}

          {/* psword 입력 필드 */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">풀네임</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={fullname}
                onChange={e => setFullname(e.target.value)}
                className="w-full rounded-md border-2 border-gray-300 px-4 py-2 text-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
            </dd>
          </div>
          {/* 토큰 입력 필드 */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">토큰</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={token}
                onChange={e => setToken(e.target.value)}
                className="w-full rounded-md border-2 border-gray-300 px-4 py-2 text-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
            </dd>
          </div>

          {/* 벤더 ID 입력 필드 */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">벤더 ID</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={vendorId}
                onChange={e => setVendorId(e.target.value)}
                className="w-full rounded-md border-2 border-gray-300 px-4 py-2 text-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
            </dd>
          </div>

          {/* 청구 ID 입력 필드 */}
          {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">청구 ID</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={billId}
                onChange={e => setBillId(e.target.value)}
                className="w-full rounded-md border-2 border-gray-300 px-4 py-2 text-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
            </dd>
          </div> */}

          {/* 금액 입력 필드 */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">누적결제금액</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={amount}
                onChange={e => setAmount(e.target.value)}
                className="w-full rounded-md border-2 border-gray-300 px-4 py-2 text-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
            </dd>
          </div>

          {/* 메모 입력 필드 */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">메모</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={memo}
                onChange={e => setMemo(e.target.value)}
                className="w-full rounded-md border-2 border-gray-300 px-4 py-2 text-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">회원등급</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={level}
                onChange={e => setLevel(e.target.value)}
                className="w-full rounded-md border-2 border-gray-300 px-4 py-2 text-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
              />
            </dd>
          </div>
          <span className="text-xs text-red-500">
            무료회원 = 0 , 유료회원 = 1
          </span>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">유료회원 만료기간</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy/MM/dd"
                className="form-control"
              />
            </dd>
          </div>
            <span className="text-xs text-red-500">
              무료회원의 경우 만료일자가 오늘날짜 기준으로 -7일 전으로 되어있습니다.
            </span>
        </dl>
      </div>
      <div className="px-4 py-6 sm:flex sm:justify-center sm:gap-4 sm:px-6">
        <button
          type="button"
          className="rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600"
          onClick={handleUpdateUserAllInfo}
      >
        수정완료
      </button>
      {/* <SuccessPopUp style={{ zIndex: 1000, position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} message="회원정보가 수정되었습니다." /> */}
      <div>
      {/* ... 페이지 내용 ... */}
    </div>
      </div>
    </div>
  )
}
