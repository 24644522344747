import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, TextField, Button } from '@mui/material';

const EmailVerificationModal = ({ open, handleClose, userEmail,username, password,phonenumber}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [timeLeft, setTimeLeft] = useState(180);
  const [isCodeSent, setIsCodeSent] = useState(false);
  

  useEffect(() => {
    if (open && timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [open, timeLeft]);

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const submitVerificationCode = () => {
    if (!verificationCode || verificationCode.length !== 6) {
      alert('인증번호를 정확히 6자리 입력해주세요.');
      return;
    }

    // 정보를 프린트하는 로직
    // console.log('Use@r Data:',  password, userEmail, phonenumber);
  
    fetch('/emailAuthCheck', {
      method: 'POST',
      body: JSON.stringify({ userEmail,username ,password,phonenumber, verificationCode }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 401) {
        throw new Error('인증번호가 올바르지 않습니다. 다시 시도해주세요.');
      } else {
        throw new Error('서버에서 오류가 발생했습니다. 다시 시도해주세요.');
      }
    })
    .then(data => {
      if (data.success) {
        alert('회원가입이 완료되었습니다.');

        window.location.href = '/login'; // '/'로 이동
      } else {
        alert('인증번호를 검증하는 데 실패했습니다. 다시 시도해주세요.');
      }
    })
    .catch(error => {
      alert(error.message);
    });
  };
  
  

  const sendVerificationCode = () => {
    // 서버로 인증번호를 전송하여 검증하는 로직을 여기에 구현하세요.
    fetch('/emailAuth', {
      method: 'POST',
      body: JSON.stringify({ userEmail , phonenumber}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (response.status === 400) {
        throw new Error('이미 가입 된 이메일입니다. 다른 이메일을 사용해주세요.');
      } else if (response.status === 401) {
        throw new Error('너무 많은 인증번호 요청입니다. 30초 후에 다시 시도해주세요.');
      } else if (response.status === 402) {
        throw new Error('이미 가입 된 휴대전화번호입니다. 다른 휴대전화번호를 사용해주세요.');
      } else {
        return response.json();
      }
    })
    .then(data => {
      // 검증 결과에 따른 처리 로직을 구현하세요.
      setIsCodeSent(true);
      if (data.success) {
        alert('인증번호를 보냈습니다. 이메일을 확인해주세요.');
      } else {
        alert('인증번호를 보내는 데 실패했습니다. 다시 시도해주세요.');
      }
    })
    .catch(error => {
      console.error('Error:', error);
      alert(error.message);
    });
  };

  // const sendVerificationCode = () => {
  //   // 인증번호 보내기 로직 구현
  //   // 예시: 서버에 인증번호 요청
  //   setIsCodeSent(true);
  //   // 서버로부터 응답이 올 때까지 기다린 후 setIsCodeSent(true)로 설정
  // };

  // 시간이 0이 되었을 때의 처리 로직을 구현하세요.
  useEffect(() => {
    if (timeLeft === 0) {
      // 인증 시간 만료 처리
      handleClose();
      alert('인증 시간이 만료되었습니다. 다시 시도해주세요.');
    }
  }, [timeLeft, handleClose]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400, // 고정된 너비를 사용하거나 반응형으로 설정할 수 있습니다.
          backgroundColor: 'white',
          borderRadius: '8px', // 모서리를 둥글게
          border: 'none', // 테두리 없음
          boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)', // 그림자 추가
          padding: '20px', // 내부 여백
        }}>
          <h2 id="transition-modal-title">이메일 인증</h2>
          <p id="transition-modal-description">이메일로 전송된 인증번호를 입력하세요.</p>

          <TextField
            margin="normal"
            fullWidth
            label="이메일 주소"
            defaultValue={userEmail}
            InputProps={{
              readOnly: true,
            }}
          />
          
          <TextField
            margin="normal"
            fullWidth
            label="인증번호"
            value={verificationCode}h
            onChange={handleVerificationCodeChange}
            placeholder='인증번호 6자리를 입력하세요.'
          />
          
          <p>인증 만료 시간: {`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}`}</p>
          <p>인증번호가 오지 않는다면 1분 뒤에 메일함을 확인해주세요.</p>
          <p>인증번호가 오지 않는다면 스팸 메일함도 확인해주세요.</p>
          
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={sendVerificationCode}
            disabled={isCodeSent}
          >
            인증번호 전송
          </Button>

          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={submitVerificationCode}
            disabled={!isCodeSent}
          >
            인증번호 확인
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="warning"
            onClick={sendVerificationCode}
            // disabled={isCodeSent}
          >
            인증번호 재전송
          </Button>
          
        </div>
      </Fade>
    </Modal>
  );
};

export default EmailVerificationModal;
