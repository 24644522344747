import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, Typography, Link, Container } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox'; // Checkbox import
import FormControlLabel from '@mui/material/FormControlLabel'; // FormControlLabel import

import EmailVerificationModal from '../../components/emailAuth';
// import ParticlesSystem from '../../components/ParticlesSystem';
import SuccessPopUp from '../../components/successPopUp';


export const AgreementForm = ({ values, setFieldValue, touched, errors, checked, setChecked, onCheckedChange }) => {
  const [successShowPopup, setSuccessShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [popupMessage, setPopupMessage] = useState('');

  const openPopup = (title) => {
    if (title === '서비스 이용약관 동의') {
      window.open('/marketingagree', '_blank');
    } else if (title === '개인정보 수집・이용 동의') {
      window.open('/privacy', '_blank');
    } else if (title === '업데이트 소식 및 마케팅 정보 수신 동의') {
      setPopupMessage(marketingMessage);
      setPopupTitle(title);
      setSuccessShowPopup(true);
    }
  };

  const closePopup = () => {
    setPopupTitle(''); // 초기화
    setPopupMessage(''); // 초기화
    setSuccessShowPopup(false);
  };

  const [localChecked, setLocalChecked] = useState({
    all: false,
    over14: false,
    terms: false,
    privacy: false,
    updates: false,
  });

  const handleChange = (event) => {
    const { name, checked: isChecked } = event.target;
    const newChecked = {
      ...localChecked,
      [name]: isChecked,
      ...(name === 'all' ? { over14: isChecked, terms: isChecked, privacy: isChecked, updates: isChecked } : {})
    };

    setLocalChecked(newChecked);
    onCheckedChange(newChecked); // 새로운 체크 상태를 상위 컴포넌트에 전달


    // Formik 상태 업데이트
    if (name === 'all') {
      setFieldValue('over14', isChecked);
      setFieldValue('terms', isChecked);
      setFieldValue('privacy', isChecked);
      setFieldValue('updates', isChecked);
    } else {
      setFieldValue(name, isChecked);
    }
  };


  return (
    <div>
      
      <FormControlLabel
        control={
          <Checkbox
            checked={checked.all}
            onChange={handleChange}
            name="all"
            color="primary"
          />
        }
        label={<Typography variant="body2">전체 동의</Typography>}
      />
      <Divider />
      <FormControlLabel
        control={
          <Checkbox
            checked={checked.over14}
            onChange={handleChange}
            name="over14"
            color="primary"
          />
        }
        label={<Typography variant="body2">본인은 만 14세 이상입니다. (필수)</Typography>}
      />
      {touched.over14 && errors.over14 ? <div style={{ color: 'red' }}>{errors.over14}</div> : null}
      {/* <Divider /> */}
      {/* 나머지 체크박스와 라벨도 이와 같은 방식으로 추가 */}
      <FormControlLabel
        control={
          <Checkbox
            checked={checked.terms}
            onChange={handleChange}
            name="terms"
            color="primary"
          />
        }
        label={
          <><Typography variant="body2">서비스 이용약관 동의 (필수) 
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => openPopup('서비스 이용약관 동의', '여기에 약관 내용을 넣으세요.')}>
              약관보기
            </span>
          </Typography></>
        }
      />
      {touched.terms && errors.terms ? <div style={{ color: 'red' }}>{errors.terms}</div> : null}
      <FormControlLabel
        control={
          <Checkbox
            checked={checked.privacy}
            onChange={handleChange}
            name="privacy"
            color="primary"
          />
        }
        label={
          <><Typography variant="body2">개인정보 수집・이용 동의 (필수) 
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => openPopup('개인정보 수집・이용 동의', '여기에 약관 내용을 넣으세요.')}>
              약관보기
            </span>
          </Typography></>
        }
        // label={<><Typography variant="body2">개인정보 수집・이용 동의 (필수) <Link href="/infoAgreeForm">약관보기</Link></Typography></>}
      />
      {touched.privacy && errors.privacy ? <div style={{ color: 'red' }}>{errors.privacy}</div> : null}

      <FormControlLabel
        control={
          <Checkbox
            checked={checked.updates}
            onChange={handleChange}
            name="updates"
            color="primary"
          />
        }
        label={
          <><Typography variant="body2">업데이트 소식 및 마케팅 정보 수신 동의 (선택) 
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => openPopup('업데이트 소식 및 마케팅 정보 수신 동의', '여기에 약관 내용을 넣으세요.')}>
              약관보기
            </span>
          </Typography></>
        }
        // label={<><Typography variant="body2">업데이트 소식 및 마케팅 정보 수신 동의 (선택) <Link href="#">약관보기</Link></Typography></>}
      />
      {
        successShowPopup &&
        <SuccessPopUp
          title={popupTitle}
          message={popupMessage}
          onClose={closePopup}
        />
      }
    </div>

  );
}

// export default AgreementForm;

// const signUpSchema = yup.object().shape({
//   username: yup.string().required("사용자 이름이 필요합니다"),
//   email: yup.string().email("유효한 이메일을 입력하세요").required("이메일이 필요합니다"),
//   password: yup.string().required("비밀번호가 필요합니다"),
//   repassword: yup.string().oneOf([yup.ref('password'), null], '비밀번호가 일치해야 합니다')
// });
const signUpSchema = yup.object().shape({
  // username: yup
  //   .string()
  //   .matches(/^[a-zA-Z0-9]{5,20}$/, "5~20자의 영문 소문자, 숫자만 사용 가능합니다.")
  //   .required("아이디를 입력해주세요"),
  username : yup
    .string()
    .required("성함을 입력해주세요"),
  email: yup
    .string()
    .email("유효한 이메일 주소를 입력해주세요")
    .required("이메일을 입력해주세요"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "8자 이상의 영문 대소문자, 숫자, 특수문자를 사용하세요"
    )
    .required("비밀번호를 입력해주세요"),
  repassword: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "8자 이상의 영문 대소문자, 숫자, 특수문자를 사용하세요"
    )
    .required("비밀번호를 입력해주세요"),
  phonenumber: yup
    .string()
    .matches(/^01[0-9]{8,9}$/, "유효한 휴대폰번호를 입력해주세요")
    .required("휴대폰번호를 입력해주세요"),
  over14: yup
    .boolean()
    .oneOf([true], "만 14세 이상의 동의가 필요합니다."),
  terms: yup
    .boolean()
    .oneOf([true], "서비스 이용약관에 동의해주세요."),
  privacy: yup
    .boolean()
    .oneOf([true], "개인정보 수집 및 이용에 동의해주세요."), 
});
// const ParticlesAnimation = () => {
//   const mountRef = useRef(null);

//   useEffect(() => {
//     const particlesSystem = new ParticlesSystem();
//     mountRef.current.appendChild(particlesSystem.renderer.domElement);
//     particlesSystem.animate();

//     // 컴포넌트 unmount 시 렌더러에서 DOM 엘리먼트를 제거합니다.
//     return () => {
//       mountRef.current.removeChild(particlesSystem.renderer.domElement);
//     };
//   }, []);

//   return <div ref={mountRef} />;
// };


const lightTheme = createTheme({
  palette: {
    mode: 'light', // 라이트 모드로 명시적 설정
    // ... 다른 색상 설정들
  },
  // ... 다른 테마 설정들
});
const SignUp = () => {
  const [checked, setChecked] = useState({
    all: false,
    over14: false,
    terms: false,
    privacy: false,
    updates: false,
  });

  // 필수 항목들이 모두 체크되었는지 확인하는 함수
  const allRequiredChecked = () => {
    const requiredItems = ['over14', 'terms', 'privacy'];
    return requiredItems.every(item => checked[item]);
  };

  const navigate = useNavigate();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userEmail, setUserEmail] = useState(''); // 사용자 이메일 상태 관리, 필요에 따라 초기값 설정
  const [username, setUsername] = useState(''); // 사용자 이메일 상태 관리, 필요에 따라 초기값 설정
  const [password, setPassword] = useState(''); // 사용자 이메일 상태 관리, 필요에 따라 초기값 설정
  const [phonenumber, setPhonenumber] = useState(''); // 사용자 이메일 상태 관리, 필요에 따라 초기값 설정


  const handleCloseModal = () => {
    // 모달 닫기 버튼이나 백드롭 클릭 시 모달을 닫기 위해 isModalOpen 상태를 false로 설정
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Check if the tokens exist
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (accessToken && refreshToken) {
      // If the tokens exist, redirect to the /team page
      navigate('/team');
    }
  }, [navigate]); // Add navigate to the dependency array


  const handleFormSubmit = async (values, actions) => {

    // 이메일 인증을 위해 모달을 열기 전에 사용자 데이터를 상태에 저장합니다.
    setUserEmail(values.email);
    setUsername(values.username);
    setPassword(values.password);
    setPhonenumber(values.phonenumber);
    
    setIsModalOpen(true);
  };

  const handleEmailVerified = () => {
    setIsEmailVerified(true);
    handleCloseModal();
  };

  const primaryColor = '#000000';  // 진한 파란색
  const secondaryColor = '#1C4097'; // 노란색
  // const accentColor = '#A6B0D6';    // 붉은색

  const handleCheckedChange = (newChecked) => {
    setChecked(newChecked);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Container component="main" maxWidth="xs">

        <Box
          m="30px"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ color: primaryColor }} // 텍스트 색상 지정
        >
          
          <Box onClick={() => navigate('/')} display="flex" justifyContent="center" alignItems="center">
            <img
              alt="logo"
              width="200%"
              height="200%"
              src={`../../assets/coudaemonster.png`}
              style={{ cursor: "pointer", borderRadius: "10%" }}
            />
          </Box>
          {/* <ParticlesAnimation /> */}
          <Typography variant="h5" component="h1" gutterBottom>
            회원가입
          </Typography>
          <Formik
            initialValues={{
              username: "",
              email: "",
              password: "",
              repassword: "",
              over14: false,
              terms: false,
              privacy: false,
              updates: false
            }}
            validationSchema={signUpSchema}
            onSubmit={(values, actions) => handleFormSubmit(values, actions)}
          >
            {({ errors, touched, handleChange,  handleSubmit, setFieldValue, values }) => (
              <form onSubmit={handleSubmit}>
                <Typography style={{ color: '#FD4F54', fontSize: '0.875rem' }}>
                  🚨 입력한 이메일은 변경이 불가하오니 신중하게 입력해주세요. 
                </Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="이메일"
                  name="email"
                  onChange={(event) => {
                    handleChange(event);
                    setUserEmail(event.target.value);
                    // console.log("Email Value: ", event.target.value);  // 디버깅: 입력된 이메일 값 출력
                  }}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  // Input 스타일 지정
                  inputProps={{
                    style: {
                      color: primaryColor,
                      borderColor: primaryColor,
                    },
                  }}
                  // Input Label 스타일 지정
                  InputLabelProps={{
                    style: { color: primaryColor },
                  }}
                  placeholder='인증번호 받을 이메일을 입력해주세요.'
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="비밀번호"
                  name="password"
                  type="password"
                  onChange={(event) => {
                    handleChange(event);
                    setPassword(event.target.value);
                  }}
                  disabled={isEmailVerified} // 인증 성공 시 비활성화
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  // Input 스타일 지정
                  inputProps={{
                    style: {
                      color: primaryColor,
                      borderColor: primaryColor,
                    },
                    placeholder: "8~16자, 영 소문자, 숫자, 특수문자를 사용하세요." // placeholder 추가
                  }}
                  // Input Label 스타일 지정
                  InputLabelProps={{
                    style: { color: primaryColor },
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="비밀번호 확인"
                  name="repassword"
                  type="password"
                  onChange={(event) => {
                    handleChange(event);
                    setPassword(event.target.value);
                  }}
                  disabled={isEmailVerified} // 인증 성공 시 비활성화
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  // Input 스타일 지정
                  inputProps={{
                    style: {
                      color: primaryColor,
                      borderColor: primaryColor,
                    },
                  }}
                  // Input Label 스타일 지정
                  InputLabelProps={{
                    style: { color: primaryColor },
                  }}
                  placeholder='비밀번호를 한번 더 입력하세요.'
                />
                <Box mt={2}></Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="이름" 
                  name="username" // 'email'에서 'username'으로 변경
                  onChange={(event) => {
                    handleChange(event);
                    setUsername(event.target.value);
                  }}

                  error={touched.username && Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                  // Input 스타일 지정
                  inputProps={{
                    style: {
                      color: primaryColor,
                      borderColor: primaryColor,
                    },
                    placeholder: "이름을 입력하세요." // placeholder 추가
                  }}
                  // Input Label 스타일 지정
                  InputLabelProps={{
                    style: { color: primaryColor },
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="휴대폰 번호"
                  name="phonenumber"
                  onChange={(event) => {
                    handleChange(event);
                    setPhonenumber(event.target.value);
                  }}
                  error={touched.phonenumber && Boolean(errors.phonenumber)}
                  helperText={touched.phonenumber && errors.phonenumber}
                  // Input 스타일 지정
                  inputProps={{
                    style: {
                      color: primaryColor,
                      borderColor: primaryColor,
                    },
                    placeholder: "01012345678" // placeholder 추가
                  }}
                  // Input Label 스타일 지정
                  InputLabelProps={{
                    style: { color: primaryColor },
                  }}
                />
                <Box mt={2}></Box>
                <AgreementForm
                  checked={checked}
                  setChecked={setChecked}
                  onCheckedChange={handleCheckedChange}
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  errors={errors}
                />
                <Button
                  type="submit"
                  disabled={!allRequiredChecked()}
                  fullWidth
                  variant="contained"
                >
                  계속 하기
                </Button>


                <EmailVerificationModal
                  open={isModalOpen}
                  handleClose={handleCloseModal}
                  userEmail={userEmail}
                  username={username}
                  password={password}
                  phonenumber={phonenumber}
                  onVerified={handleEmailVerified}
                />

                <Box mt={2}>
                  <Link href="/login" variant="body2" sx={{ color: secondaryColor }}>
                    {"이미 아이디가 있으신가요 ? 로그인"}
                  </Link>
                </Box>
                <Box mt={2}></Box>
                <Box mt={2}></Box>
              </form>
            )}
          </Formik>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

// const initialValues = {
//   username: "", // 'email' 키를 'username'으로 변경
//   password: "",
// };

// const loginSchema = yup.object().shape({
//   username: yup.string().required("Username is required"), // 이메일 검증 대신 간단한 문자열 검증
//   password: yup.string().required("Password is required"),
// });

const marketingMessage = `
수집 항목(Collection item)	수집·이용목적(Collection Purpose)	보유 기간(Retention period)
이메일, 휴대전화번호	광고 및 이벤트 안내 메시지 발송	동의 철회 또는 회원 탈퇴 시 지체없이 파기
`;
export default SignUp;
